import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	HashRouter,
	Route,
	Link,
	Switch,
	Redirect
} from 'react-router-dom';
//import logo from './logo.svg';
import './App.css';

//import MenuAppBar from "./components/MenuAppBar";
import ClippedDrawer from "./components/ClippedDrawer";
import Login from "./components/Login/login";
import Survey from "./components/Tasks/Survey";

import axios from 'axios';
import config from './config/config';

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class App extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
        	logined: false,
        	username: "",
			token: "",
			routeData:[]
		};
	}

	componentWillMount(){
		const loginState = this.props.cookies.get("loginState");
		const username = this.props.cookies.get("username");
		const token = this.props.cookies.get("token");
		if (loginState && username && token){
			this.setState({ logined: loginState, username: username, token: token });
			sessionStorage.setItem("loginState", loginState);
			sessionStorage.setItem("username", username);
			sessionStorage.setItem("token", token.token);
		}
	}

	login = (islogined, username, token) => {
		this.setState({ logined: islogined, username: username, token: token.token });
		sessionStorage.setItem("loginState", islogined);
		sessionStorage.setItem("username", username);
		sessionStorage.setItem("token", token.token);
		this.props.cookies.set("loginState", islogined, {path: '/', maxAge: 3600, })
		this.props.cookies.set("username", username, {path: '/', maxAge: 3600, })
		this.props.cookies.set("token", token.token, {path: '/', maxAge: 3600, })
	}

	logout = () => {
		this.setState({ logined: false, username: '', token: '' });
		// this.forceUpdate();
		sessionStorage.clear();
		this.props.cookies.remove("loginState");
		this.props.cookies.remove("username");
		this.props.cookies.remove("token");
	};

	createRoute(data){
		return data.map(row=>{
			let route = row.route.toLowerCase();
			return route.substring(route.lastIndexOf('/'));
		})
	}

	componentDidMount(){
        axios.get(config.menu).then(res=>{
			// console.log("get menu")
			// console.log(res.data.results)
			// console.log(this.createRoute(res.data.results))
			this.setState({routeData:this.createRoute(res.data.results)})
		});
    }

	render() {
		// console.log(this.state.logined);
		const publicPath = '';
		//console.log(publicPath + '/login')
		return (
			<Router>
			{/* <HashRouter> */}
				<div className='App'>
					<Switch>
					<Route exact path={[publicPath + '/login',publicPath + "/"]} 			render={(props)=><Login {...props} login={this.login}/>}/>
					{/* <Route 
						exact path={[publicPath + '/dashboard', publicPath + '/invoice',publicPath + '/vendor',publicPath + '/transaction',publicPath + '/employee',
							publicPath + '/purchase order',publicPath + '/customer',publicPath + '/conflict of interest',publicPath + '/duplicate address',
							publicPath + '/duplicate po',publicPath + '/benford', publicPath + '/enterprise rm']} 		
						render={(props)=>this.state.logined ? 
							<ClippedDrawer {...props} username={this.state.username} token = {this.state.token}/> : 
							<Login {...props} login={this.login}/>}/> */}
					<Route 
						exact path={this.state.routeData} 		
						render={(props)=>this.state.logined ? 
							<ClippedDrawer {...props} username={this.state.username} token = {this.state.token} logout= {this.logout}/> : 
							<Login {...props} login={this.login}/>}/>
					<Route exact path={['/static',]} render={
						(props=>
							{
								return <Redirect to='/static'></Redirect>
							})
					}></Route>
					<Route exact path={['/admin',]} render={
						(props=>
							{
								return <Redirect to='/admin'></Redirect>
							})
					}></Route>
					<Route 
						exact path={['/survey',]}		
						render={(props)=>this.state.logined ? 
							<Survey/> : 
							<Login {...props} login={this.login}/>}/>
					<Route 						
						render={(props)=>this.state.logined ? 
							<ClippedDrawer {...props} username={this.state.username} token = {this.state.token} logout= {this.logout}/> : 
							<Login {...props} login={this.login}/>}/>
					</Switch>
     		   </div>
     		   {/* </HashRouter> */}
			</Router >
		);
	}
}

export default (withCookies)(App);
