import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFile from '@material-ui/icons/AttachFile';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchableDropDown from '../SharedComponents/SearchableDropDown';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Grid from '@material-ui/core/Grid';

import config from "../../config/config";
import setting from "../../config/setting";
import axios from 'axios';

import {text_truncate} from "../../util/StringUtil"

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

const today = moment().add('days',1).format('YYYY-MM-DD');

class TaskDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasNewTask: false,
            open: this.props.open,
            scroll: 'paper',
            questions: [{
                id: 1,
                required: true,
                attachment: false,
                question_type: "boolean",
                attachments: [],
                question_text:"Have age requirements for employees been met?",
            },
            {
                id: 2,
                required: true,
                attachment: false,
                question_type: "attachment",
                attachments: [],
                question_text:"Evidence that age requirement has been met for all new hires",
            },
            ],
            task_assignee: "",
            description: "Age requirements",
            due_date: today,
            loading: true,
        };
    }

    componentDidMount(){
        this.getTaskTemplate();
    }

    getTaskTemplate = ()=>{
        let endpoint = config.taskTemplate(this.props.id);
        // console.log(endpoint);
        axios.get(endpoint).then(
            res => {
                // console.log("update task template with new data");
                // console.log(res);
                this.setState({loading: false});
                if (res.data.length > 0)
                {
                    this.setState({
                        questions: res.data,
                        description: res.data[0].compliance_requirement,
                    });
                }
            }
        ).catch(
            error => {
                console.log(error);
                console.log("error");
                if (error.response.status == '401')
                {
                    this.props.history.push('/login');
                }
                else {
                    this.props.history.push('/http404');
                }
            }
        );
    }

    componentDidUpdate(prevProps){
        // console.log(prevProps.open + "   " + this.props.open)
        if (prevProps.open !== this.props.open)
        {
            this.setState({open:this.props.open});
        }
        if (this.messagesEnd && this.state.hasNewTask)
        {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            this.setState({hasNewTask:false});
        }
    }

    //handle close dialog
    handleClose = () => {
        this.setState({ open: false });
        this.props.close();
    };
    //handle submit dialog
    handleSubmit = () =>{
        let postObj = this.createSubmitObj();
        let isRequiredFieldFilled = true;
        for (var key in postObj){
            // console.log(postObj[key]);
            if(postObj[key] == ""){
                // console.log(key+" is null value")
                isRequiredFieldFilled = false;
            }
        }

        if (isRequiredFieldFilled){
            // console.log("submit data");
            // console.log(postObj);
            axios.post(config.tasks(this.props.id),postObj)
                .then(res=>{
                    // console.log(res);
                    this.props.submitUpdate();})
                .catch(error=>{console.log(error.response)});

            this.setState({ open: false });

            this.props.close();
        }else{
            alert("Please fill all the required field");
        }
    };

    createSubmitObj(){
        let postObj = {
            assigned_user: this.state.task_assignee,
            description: this.state.description,
            due_date: this.state.due_date,
            questions: this.state.questions,
            alert: 1,
            created_user: 1
        };
        return postObj;
    }

    handleRadioGroupChange = (e, id) => {
        let updateField = this.state.questions.find(o=>o.id == id);
        let updateqFieldIndex = this.state.questions.indexOf(updateField);
        updateField.question_type = e.target.value;
        this.setState(state => {this.state.questions[updateqFieldIndex] = updateField});

        this.forceUpdate();
    };

    handleTextfieldChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        // console.log("in text field change")
        // console.log(name)
        // console.log(value)
        this.setState(state =>{this.state[name] = value;});
        this.forceUpdate();
    };
    handleChange = name => selectedOption => {
        // console.log(name);
        // console.log(`Option selected:`, selectedOption);
        if (selectedOption)
        {        
            // let name = selectedOption.label;
            let value = selectedOption.value;
            // console.log(name)
            // console.log(value)
            this.setState(state =>{this.state[name] = value;});
        }
    }

    handleFileUpload = (e) => {
        let value = e.target.value;
        //to do must know which question does the question belog
        // this.setState(state =>{this.state.task["file"].push(value);});
    };

    // handleQuestionAdd = (id) => {
    //     this.setState({hasNewTask: true});
    // };

    handleQuestionDelete = (id) => {

        let removeobj = this.state.questions.find(o=>o.id == id);
        let removeobjIndex = this.state.questions.indexOf(removeobj);

        if (removeobjIndex != -1){
            this.setState(state => {this.state.questions.splice(removeobjIndex, 1)});
        }
        this.forceUpdate();
    };


    QuestionFileUpdateRequest = (formData) => {
        return axios.post(config.attachment, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res)=>{
            // console.log("upload file res");
            // console.log(res);
            return res.data.id;
        })
            .catch(err=>{ console.log(err.response); });
    };

    handleQuestionUpdate = (e, id) => {
        let updateObj = this.state.questions.find(o=>o.id == id);
        let updatequestionIndex = this.state.questions.indexOf(updateObj);

        if(e.target.files != null){
            // console.log(this.state.questions);
            updateObj.attachments.push(e.target.files[0]);
        }


        let name = e.target.name;
        let value = e.target.value;
        // console.log(name);
        // console.log(value);

        if (name === "attachment_id"){
            let attachment = e.target.files[0];

            var formData = new FormData();
            var uploads = attachment;
            // console.log(uploads);
            formData.append("file", uploads);
            // console.log ("formdata");
            // console.log(formData);

            this.QuestionFileUpdateRequest(formData).then(data => {
                updateObj.attachment_id = data;
                updateObj.attachments.push(uploads);
                // console.log("updateobj");
                // console.log(updateObj);
            });

        }else if (name === "question_text") {
            updateObj["question_text"] = value;
        }
        else {
            this.state[name] = value;
        }
        // console.log(id);
        // console.log(updatequestionIndex);
        // console.log(updateObj);
        // console.log(this.state.questions);
        // console.log (questionType);

        this.setState(state => {this.state.questions[updatequestionIndex] = updateObj});
        this.forceUpdate();
    };

    render() {
        let question = {
            id: 0,
            question_text: "",
            required: false,
            attachment: false,
            question_type: "text"
        };
        const suggestions = this.props.users ? this.props.users.map(user=>({
            value: user.email,
            label: user.email
        })) : undefined;
        const {loading} = this.state;
        // console.log("in dialog render")
        // console.log(this.props.users)
        return (
            <Dialog
                open={this.state.open}
                //onClose={this.handleClose}
                scroll={this.state.scroll}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">{"Create Task"}</DialogTitle>
                
                <DialogContent style={{ width: "55vh", height: "100vh" }}>
                {this.state.loading ?
                 <Grid container justify="center" align="center">
                    <Grid item>
                        <Loader 
                            type="Oval"
                            color={setting.COLOR.MEDIUM_BLUE}
                            height="50"	
                            width="50"
                        />
                    </Grid>
                </Grid>
                :
                <React.Fragment>
                    <DialogContentText>
                            Task assignee (Email address)
                    </DialogContentText>
                    <SearchableDropDown 
                        options= {suggestions}
                        changeHandler={this.handleChange("task_assignee")}
                        placeholder={"Select a user"}
                    />                  
                    <DialogContentText>
                        Compliance Requirement
                    </DialogContentText>
                    <TextField
                        id="name"
                        type="text"
                        fullWidth
                        value = {this.state.description}
                        name="description"
                        required={true}
                        onChange = {this.handleTextfieldChange}
                        multiline
                    />
                    <DialogContentText>
                                Due date
                    </DialogContentText>
                    <TextField
                        id="name"
                        type="date"
                        fullWidth
                        name="due_date"
                        required="required"
                        value={this.state.due_date}
                        onChange = {this.handleTextfieldChange}
                    />

                    <div style={{ height: "1vh" }}></div>
                    <Divider />
                    <DialogContentText>
                        Please list the evidence required:
                    </DialogContentText>
                    {this.state.questions.map((question) => {
                        // console.log(question);
                        return (
                            <div key = {question.id}>
                                <p>
                                    {this.state.questions.indexOf(question) + 1}. 
                                    <TextField
                                        multiline
                                        rowsMax="4"
                                        id="title"
                                        type="email"
                                        style={{ width: "20vw" }}
                                        placeholder={"Description"}
                                        name = {"question_text"}
                                        required="required"
                                        value={question.question_text}
                                        onChange = {(e) => {this.handleQuestionUpdate(e, question.id);}}
                                    />

                                    <IconButton aria-label="Delete" onClick={()=>{this.handleQuestionDelete(question.id);}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </p>
                                <p style={{marginLeft: "2vw"}}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="Gender"
                                            name="gender1"
                                            value={question.question_type}
                                            onChange={(e) => {this.handleRadioGroupChange(e, question.id)}}
                                            row
                                            required="required"
                                            style={{ display: 'inline-flex' }}
                                        >
                                            <FormControlLabel value="text" control={<Radio />} label="Free text" />
                                            <FormControlLabel value="numbers" control={<Radio />} label="Numbers" />
                                            <FormControlLabel value="date" control={<Radio />} label="Date" />
                                            <FormControlLabel value="boolean" control={<Radio />} label="Yes/No" />
                                            <FormControlLabel value="attachment" control={<Radio />} label="Attachment" />
                                        </RadioGroup>
                                    </FormControl>
                                </p>
                                <p>
                                    <input
                                        hidden
                                        id={"attach-questoin-file" + question.id}
                                        // multiple
                                        required="required"
                                        type="file"
                                        name = {"attachment_id"}
                                        onChange = {(e) => {this.handleQuestionUpdate( e, question.id)}}
                                    />
                                    {/* <label htmlFor={"attach-questoin-file" + question.id}>
                                        <Button 
                                        variant="contained" 
                                        size="small" 
                                        component="span" 
                                        style={question.question_type.toLowerCase() != "attachment" ? { display: 'none' } : { display: 'inline-flex' }}>
                                            <AttachFile />
                                            Attach File
                                        </Button>
                                        <span style={{marginLeft: "1vw"}}>
                                            {!question.hasOwnProperty('attachments') || question.attachments.length == 0 ? null : 
                                                text_truncate(question.attachments[0].name,20)}
                                        </span>
                                    </label> */}
                                </p>
                            </div>
                        );
                    })}
                    <Fab style={{position: 'absolute',bottom: '2px',left: '2px',}}color="primary" onClick={() => {
                        let index = this.state.questions.length + 1;
                        question.id = index;
                        question.type = "Text";
                        question.attachments = [];
                        this.state.questions.push(question);
                        this.setState({ questions: this.state.questions });
                        this.setState({hasNewTask: true});
                        // this.handleQuestionAdd(question.id);
                        // console.log(this.state.questions);
                    }}><AddIcon /></Fab>
                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                    </React.Fragment>
                }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        Discard
                    </Button>
                    <Button onClick={this.handleSubmit} disabled={loading}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withRouter(withStyles(styles)(TaskDialog));