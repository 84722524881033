import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Divider } from '@material-ui/core';
import ListDetail from "./ListDetail";
//searchBar related import
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from 'react-loader-spinner';

import config from "../../config/config";
import setting from "../../config/setting";
import axios from 'axios';

let counter = 0;
function createData(alertId, entity, creator, PO1, PO2, status, date) {
    counter += 1;
    return { alertId, entity, creator, PO1, PO2, status, date };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



class EnhancedTableHead extends React.Component {

    constructor(props) {
        super(props);
        this.getTableHeader = this.getTableHeader.bind(this);
        this.state = {
            typename:null,
            rows: [],
        }
    }
    componentWillMount() {
        let pathtypename = this.props.type;
        // console.log(this.props);
        // console.log("header type name" + this.props.type);
        this.setState({typename: pathtypename});
    }
    componentDidMount() {
        this.getTableHeader();
    }
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    getTableHeader = () => {
        // if(this.state.typename == null){
        //     this.setState({ rows: [] });
        // }else{
        //     axios.get(config.alerts, {
        //         params:{'routine':this.state.typename}
        //     })
        //         .then(res => {
        //             // console.log("alerts routine header" + this.state.typename);
        //             // console.log(res);
        //             let headers = res.data.table_def;
        //             this.setState({ rows: headers })
        //     });
        // }

        let table_def= ["id","risk rating","compliance requirement","status","due date","country"]
        this.setState({ rows: table_def })
    }

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {this.state.rows.map(row => {
                        return (
                            <TableCell>
                                {row}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        height: 'flex'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    label_high: {
        padding: "0px 23px 0px 23px",
    },
    label_low: {
        padding: "0px 23px 0px 23px",
    },
});

class EnhancedTableToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.getDescriptions = this.getDescriptions.bind(this);
        this.state = {
            typename:null,
            routine: "",
            description: "",
            num_high_risk_alerts: "1",
            num_medium_risk_alerts: "1",
            num_low_risk_alerts: "1",
        };
    }
    componentWillMount() {
        let pathtypename = this.props.type;
        this.setState({ typename: pathtypename });
    }
    componentDidMount() {
        this.getDescriptions();
    }
    getDescriptions() {
        // if(this.state.typename == null){
        //     this.setState({
        //             routine: "",
        //             description: "",
        //             num_high_risk_alerts: "",
        //             num_medium_risk_alerts: "",
        //             num_low_risk_alerts: ""
        //         });
        // }else{
        // axios.get(config.alerts, {
        //     params:{'routine':this.state.typename}
        // }).then(
        //     res => {
        //         this.setState({
        //             routine: res.data.routine,
        //             description: res.data.description,
        //             num_high_risk_alerts: res.data.num_high_risk_alerts,
        //             num_medium_risk_alerts: res.data.num_medium_risk_alerts,
        //             num_low_risk_alerts: res.data.num_low_risk_alerts
        //         });
        //     });
        // }
    }
    render() {
        const { numSelected, classes, num_high_risk_alerts, num_medium_risk_alerts, num_low_risk_alerts } = this.props;
        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subheading">
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <div style={{ textAlign: "left",}}>
                            <Typography variant="title" id="tableTitle">
                                {this.state.routine}
                            </Typography>
                            <div style={{ height: "1vh" }}></div>
                            <Typography id="tableTitle">
                                {this.state.description}
                            </Typography>
                            <div style={{ height: "1vh" }}></div>
                            <span>
                                <Chip
                                    avatar={
                                        <Avatar style={{ background: setting.COLOR.RED, color: "snow" }}>
                                            {num_high_risk_alerts}
                                        </Avatar>
                                    }
                                    label="high"
                                    style={{ border: "1px solid".concat(" ", setting.COLOR.RED), margin: 'auto', marginRight: "1vh",}}
                                    variant="outlined"
                                    classes = {{
                                        label: classes.label_high,
                                    }}
                                />
                                <Chip
                                    avatar={
                                        <Avatar style={{ background: setting.COLOR.YELLOW, color: "snow" }}>
                                            {num_medium_risk_alerts}
                                        </Avatar>
                                    }
                                    label="medium"
                                    style={{ border: "1px solid".concat(" ", setting.COLOR.YELLOW), marginRight: "1vh" ,}}
                                    variant="outlined"
                                />
                                <Chip
                                    avatar={
                                        <Avatar style={{ background: setting.COLOR.GREEN, color: "snow" }}>
                                            {num_low_risk_alerts}
                                        </Avatar>
                                    }
                                    label="low"
                                    style={{ border: "1px solid".concat(" ", setting.COLOR.GREEN) , marginRight: "1vh",}}
                                    variant="outlined"
                                    classes = {{
                                        label: classes.label_low,
                                    }}
                                />
                            </span>
                            <span></span>
                        </div>
                    )}
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <Button variant="outlined" size="small" onClick={this.handleViewToggle}>Action..</Button>
                    ) : (
                        <Tooltip title={this.props.details? "Hide Details":"Show Details"}>
                            <IconButton aria-label="Details" disabled={!this.props.selectedAlert} onClick={this.props.handleDetailViewToggle}>
                                {this.props.details? <ArrowRightIcon fontSize="large"/>:<ArrowLeftIcon fontSize="large"/>}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Toolbar>
        );
    }
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        // height:'calc(100% - 80px)',
        // height: '-webkit-calc(100% - 80px)',
        // marginTop: theme.spacing.unit * 1,
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
        // height: 'calc(100% - 200px)'
    },
    contentWrapper: {
        height: '100%',
    },
    margin: {
        height: 56,
        marginLeft: theme.spacing.unit,
        padding: 8,
    },
    grid:{
        borderRight: "2px solid grey",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        width: "100%",
        // maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    clickable: {
        cursor: 'pointer',
    },
});

class MasterRoutineTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typename: null,
            open: true,
            anchor: 'right',
            order: 'asc',
            orderBy: 'id',
            list: 6,
            selected: [],
            data: [
            ],
            fields: [],
            page: 0,
            rowsPerPage: 10,
            selectedAlert: null,
            details: false,
            filtered: [],
            filter: [
                'Completed',
                'Open',
                'Overdue',
            ],
            height:0,
            width:0,
        };
        this.getAlerts = this.getAlerts.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }
    componentWillMount() {
        let pathtypename = this.props.type;
        this.setState({typename: pathtypename}) 
    }
    componentDidMount() {
        this.getAlerts();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidUpdate(prevProps){
        if (this.props.type !== prevProps.type) {
            this.getAlerts();
        }
    }
    handleDetailViewToggle = () => {
        // console.log("in toggles")
        // console.log(this.state.details);
        this.setState({
            details:!this.state.details,
        });
    }
    getAlerts() {
        let fake_items = [
            {
                id:"ID-LS-002",
                risk_rating:"high",
                compliance_requirement:"Requirements and restrictions of who can be engaged as a worker",
                status:"Completed",
                due_date:"2019-03-01",
                country:"Indonesia",
            },
            {
                id:"ID-LS-003",
                risk_rating:"low",
                compliance_requirement:"Legal protections against discrimination",
                status:"Open",
                due_date:"due in 1 week",
                country:"Indonesia",
            },
            {
                id:"ID-LS-004",
                risk_rating:"medium",
                compliance_requirement:"Requirements for foreign employees",
                status:"Overdue",
                due_date:"overdue for 3 days",
                country:"Indonesia",
            },
        ]

        let fake_data = {
            data:fake_items,
            filtered:fake_items,
            table_def: ["compliance_id","risk_rating","compliance_requirement","status","due_date","country"]
        }

        // this.setState({
        //     data: fake_data.data,
        //     filtered: fake_data.data,
        //     fields: fake_data.table_def,
        // });

        this.setState({
            loading: true,
            details: false,
        },
        () => {
            let endpoint = config.alerts;
            let params = {'routine': this.props.type}
            if (this.props.country){
                endpoint = config.alertsByCountry;
                params = {'country': this.props.country}
            }
            axios.get(endpoint, {
                params:params
            }).then(
                res => {
                    // console.log("update routine with new data");
                    // console.log(res);
                    // console.log(res.data.items);
                    // console.log(res.data.table_def);
                    // console.log(fake_items);
                    this.setState({
                        data: res.data.items,
                        filtered: res.data.items,
                        // fields: res.data.table_def,
                        fields: fake_data.table_def,
                        selectedAlert: null,
                        loading: false,
                    });
                    if (this.props.searchVal){
                        const dummyEvent= {
                            target: {
                                value: this.props.searchVal
                            }
                        }
                        this._onChangeSearch(dummyEvent);
                        this.setState({
                            selectedAlert: res.data.items.find(item => item.compliance_id.toLowerCase() === this.props.searchVal.toLowerCase()), 
                            details: true
                        });
                    }
                }
            ).catch(
                error => {
                    console.log(error);
                    console.log("error");
                    if (error.response.status == '401')
                    {
                        this.props.history.push('/login');
                    }
                    else {
                        this.props.history.push('/http404');
                    }
                }
            );
        }
        );
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({ selected: state.data.map(n => n.compliance_id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id, alert) => {
        // console.log("clicked ID is "+id);
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        this.setState({ selectedAlert: alert })
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };
    handleDetailClick(event, alert){
        this.setState({selectedAlert: alert, details:true});
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    _onChangeSearch = (e) =>{
        console.log(e.target.value);
        if (e.target.value){
            // console.log(e.target.value);
            // console.log(this.state.data);
            var filtered = this.state.data.filter(function(itm){
                const isIncluded = (itm.compliance_requirement && itm.compliance_id) ? (itm.compliance_requirement.toLowerCase().includes(e.target.value.toLowerCase()) || itm.compliance_id.toLowerCase().includes(e.target.value.toLowerCase())) : true;
                return isIncluded;
            });
        } else {
            var filtered = this.state.data;
        }
        
        //console.log(filtered);
        this.setState({filtered: filtered, inputValue: e.target.value})
    };

    handleFilterChange = event => {
        this.setState({ filter: event.target.value });
    };

    renderColorCell = content => {
        switch (content.toLowerCase()){
            case "high":
            case "overdue":
            return <TableCell>
                <Chip label={content} style={{background: setting.COLOR.RED, color: "white", width: "100%"}}></Chip>
            </TableCell>;
            case "medium":
            case "open":
            return <TableCell>
                <Chip label={content} style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
            </TableCell>;
            case "low":
            case "completed":
            return <TableCell>
                <Chip label={content} style={{background: setting.COLOR.GREEN, color: "white", width: "100%"}}></Chip>
            </TableCell>;
            default:
            return <TableCell>{content ? content : 'NIL'}</TableCell>;
        }
    };

    render() {
        const { classes, theme } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, anchor, open, details, list , filtered,detailSelected, filter, selectedAlert, loading}  = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        console.log("passing alerts: " + (selectedAlert ? selectedAlert.compliance_id : "not found"));
        const detailsView = (<Grid item md={6} className={classes.contentWrapper}>
            <ListDetail username={this.props.username} selectedAlert = {selectedAlert} queryID={selectedAlert?selectedAlert.compliance_id:null} isDemo={true}/>
        </Grid>);
        const filterOptions = [
            'Completed',
            'Open',
            'Overdue',
        ];
        const filteredAlerts = filtered.filter(function(itm){
            return itm.status ? filter.includes(itm.status) : true;
        })
        const searchBar = (<div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                    <SearchIcon/>               
                </Grid>
                <Grid item style={{width: "40%"}}>
                    <TextField id="input-with-icon-grid" label="Search..." InputLabelProps={{shrink:this.state.inputValue?true:false}} 
                        fullWidth={true} onChange={this._onChangeSearch} value={this.state.inputValue}/>
                </Grid>
                <Grid item style={{height: "64px", width: "40%"}}>
                    <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-checkbox">Status Filter</InputLabel>
                    <Select
                        multiple
                        value={this.state.filter}
                        onChange={this.handleFilterChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                        // MenuProps={MenuProps}
                    >
                        {filterOptions.map(name => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={this.state.filter.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
        </div>);
        const table_height = (this.state.height - 217) + "px";
        // console.log(table_height);
        // console.log("in tables")
        const style_height = {
            height: table_height,
        };
        // console.log(filteredAlerts)
        let high_risk_count = filteredAlerts.filter(item => item.risk_rating.toLowerCase() === 'high').length;
        let medium_risk_count = filteredAlerts.filter(item => item.risk_rating.toLowerCase() === 'medium').length;
        let low_risk_count = filteredAlerts.filter(item => item.risk_rating.toLowerCase() === 'low').length;
        return (
            <div className={classes.root}>
                <Grid container padding="true">
                    <Grid item md={this.state.details ? 6:12} className={classes.grid}>
                    <EnhancedTableToolbar numSelected={selected.length} type={this.state.typename} 
                        num_high_risk_alerts={high_risk_count} num_low_risk_alerts={low_risk_count} num_medium_risk_alerts={medium_risk_count} 
                        handleDetailViewToggle={this.handleDetailViewToggle} details={this.state.details} selectedAlert={selectedAlert}/>
                        {searchBar}
                        <div className={classes.tableWrapper} style={style_height}>                            
                            <Table className={classes.table} aria-labelledby="tableTitle" padding="dense">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    type={this.state.typename}
                                />
                                {loading ?
                                (
                                    <TableCell colspan={this.state.fields.length? this.state.fields.length : "7"}>
                                        <Grid container justify="center">
                                            <Loader 
                                                type="Oval"
                                                color={setting.COLOR.MEDIUM_BLUE}
                                                height="50"	
                                                width="50"
                                            />
                                        </Grid>
                                    </TableCell>
                                )
                                :
                                (
                                <TableBody>
                                    {filteredAlerts
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            const isSelected = this.isSelected(n.compliance_id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.compliance_id}
                                                    selected={selectedAlert == null ? isSelected : n.compliance_id === selectedAlert.compliance_id}
                                                    onClick={event => this.handleDetailClick(event, n)}
                                                    className={classes.clickable}
                                                >
                                                    <TableCell onClick={event => this.handleClick(event, n.compliance_id, n)}>
                                                        <Checkbox checked={isSelected}/>
                                                    </TableCell>
                                                    {
                                                        this.state.fields.map(f => {
                                                            return (
                                                            f==="status" || f==="risk_rating" ?
                                                            this.renderColorCell(n[f])
                                                            :
                                                            <TableCell>{n[f] ? n[f] : 'NIL'}</TableCell>);
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                    {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )} */}
                                </TableBody>
                                )}
                            </Table>
                        <TablePagination
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        /> 
                        </div>                       
                    </Grid>
                    {this.state.details ? detailsView : null}
                </Grid>
                <Divider />
            </div>
        );
    }
}

MasterRoutineTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MasterRoutineTable));