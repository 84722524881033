import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import Calendar from 'react-calendar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIOSIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardIOSIcon from '@material-ui/icons/ArrowForwardIos';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
// import ReactTooltip from 'react-tooltip';

import config from "../../config/config";
import setting from "../../config/setting";
import axios from 'axios';

import '../Dashboard/MyDashboardStyles.css';

const styles = theme => ({
    // card: {
    //     //maxWidth: 345,
    //     height: "15vh",
    // },
    chart: {
        height: "40vh",
        minHeight: "350px",
    },
    transaction: {
        backgroudColor: "rgba(188,32,75,1)",
        color: "snow"
    },
    // cardAction: {
    //     width: "100%",
    //     height: "100%"
    // },
    media: {
        // ⚠️ object-fit is not supported by IE11.
        objectFit: 'cover',
    },
    formControl: {
        margin: theme.spacing.unit * 3
    },
    calendar:{
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        // top: "50%",
        // transform: "translateY(-50%)",
    },
    today:{
        borderRadius: '50% !important',
        borderColor: '#00338D !important',
        borderWidth: '0.5px !important',
        border: 'solid !important',
        // background: '#ffffff',
        // backgroundColor: '#ffffff !important', //use important to prevent hover and enabled styles overridding
        // color: '#000 !important', //use important to prevent hover and enabled styles overridding
    },
    red:{
        borderRadius: '50%',
        background: setting.COLOR.RED,
        backgroundColor: setting.COLOR.RED.concat(' ','!important'), //use important to prevent hover and enabled styles overridding
        color: '#fff !important', //use important to prevent hover and enabled styles overridding
    },
    ember:{
        borderRadius: '50%',
        background: setting.COLOR.YELLOW,
        backgroundColor: setting.COLOR.YELLOW.concat(' ','!important'), //use important to prevent hover and enabled styles overridding
        color: '#fff !important', //use important to prevent hover and enabled styles overridding
    },
    grey:{
        borderRadius: '50%',
        background: setting.COLOR.OLIVE,
        backgroundColor: setting.COLOR.OLIVE.concat(' ','!important'), //use important to prevent hover and enabled styles overridding
        color: '#fff !important', //use important to prevent hover and enabled styles overridding
    },
    row: {
        height: '5vh',
    },
    clickable: {
        cursor: 'pointer',
    },
});
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const getState = () => ({
    datasets: [{
        data: [getRandomInt(50, 200), getRandomInt(100, 150), getRandomInt(150, 250)],
        backgroundColor: [
            '#CCC',
            '#36A2EB',
            '#FFCE56'
        ],
        hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56'
        ]
    }]
});
const today = moment().startOf('day');
class Dashboard extends Component {
    constructor(props) {
		super(props);
        this.state = {
            selectedDate: 'default',
            openAlerts: [],
        }
    }

    getTasks(){
        axios.get(config.task)
        .then(res => {
            let tasks = res.data;
            let task_data = []
            task_data.push(tasks.filter(task=>task.status==='Draft').length)
            task_data.push(tasks.filter(task=>task.status==='Pending Response').length)
            task_data.push(tasks.filter(task=>task.status==='Pending Review').length)
            task_data.push(tasks.filter(task=>task.status==='Rejected').length)
            task_data.push(tasks.filter(task=>task.status==='Completed').length)
            // console.log("in dashboard")
            // console.log(res)
            // console.log(alert_data)
            let data={
                labels: [
                'Draft',
                'Pending Response',
                'Pending Review',
                'Rejected',
                'Completed'
                ],
                datasets:[{
                    data:task_data,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#990099',
                        '#ff9900'
                        ],
                        hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#FF6384',
                        '#36A2EB'
                        ]
                }]
            }
            this.setState({
                taskStatusData: data
            })
        })
    }
    getAlerts(){
        axios.get(config.openAlert)
        .then(res => {
            let alerts = res.data;
            let alert_data = alerts.map(alert=>alert.compliance_id)
            console.log("in dashboard")
            console.log(res)
            console.log(alert_data)
            this.setState({
                openAlerts: alert_data
            })
        })
    }
    getInitialState() {
        return getState();
    }

    componentDidMount(){
        this.getAlerts();
        this.getTasks();
    }

    handleClickAlert = (e, country, id) =>{
        this.props.history.push('/' + country.toLowerCase() + '?searchVal=' + id.toLowerCase());
    }

    componentWillMount() {
        //this.setState(getState());
        // regular refresh
        // setInterval(() => {
        //     // this.setState(getState());
        //     this.getAlerts();
        //     this.getTasks();
        // }, 60000);
    }
    shiftDate(date, numDays) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + numDays);
        return newDate;
      }
      
    getRange(count) {
        return Array.from({ length: count }, (_, i) => i);
      }
      
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
    render() {
        const { classes } = this.props;
        const { selectedDate, openAlerts } = this.state;
        console.log(openAlerts);
        const calendar = (
            <Calendar 
            className={classes.calendar}
            // tileDisabled={({activeStartDate, date, view }) => date.getDate() < today.getDate() }
            prev2Label={<Tooltip title="Previous Year"><ArrowBackIcon /></Tooltip>}
            prevLabel={<Tooltip title="Previous Month"><ArrowBackIOSIcon /></Tooltip>}
            nextLabel={<Tooltip title="Next Month"><ArrowForwardIOSIcon /></Tooltip>}
            next2Label={<Tooltip title="Next Year"><ArrowForwardIcon /></Tooltip>}
            tileClassName={({ date, view }) => {
                // console.log(date)
                // console.log(overdue_date)
                // console.log(moment(date).diff(overdue_date, 'days'))
                if (moment(date).diff(overdue_date, 'days') === 0 )
                {
                    return classes.red;
                } else if (moment(date).diff(due_one_week_date, 'days') === 0)
                {
                    return classes.ember;
                } else if (moment(date).diff(due_12_day_date, 'days') === 0 || moment(date).diff(due_25_day_date, 'days') === 0)
                {
                    return classes.grey;
                } else if (Math.ceil(moment(date).diff(today, 'days', true)) === 0)
                {
                    return classes.today;
                } else {
                    return null;
                }
            }}
            // tileContent={({ date, view }) => date.getDate() > today.getDate() && date.getDay() === 6 ? <p>!</p> : null}
            onClickDay={(value) => this.setState({selectedDate : value})}
            />
        );

        const due_one_week_date = moment(today, "DD-MM-YYYY").add('days', 5);
        const due_12_day_date = moment(today, "DD-MM-YYYY").add('days', 12);
        const due_25_day_date = moment(today, "DD-MM-YYYY").add('days', 25);
        const overdue_date = moment(today, "DD-MM-YYYY").add('days', -3);

        const requirement_detail = (
            <div className={classes.root}>
            {!moment(selectedDate).isValid() ? 
            <Typography variant='subtitle1'>Compliance requirements
            {selectedDate!=='default' ? <Button style={{marginLeft: 10}} variant='raised' size='small' onClick={(event)=>this.setState({selectedDate:'default'})}>show all</Button> : null}
            </Typography> : 
            (<Typography variant='subtitle1'>Compliance requirements for {moment(selectedDate).format(setting.date_format)}
            <Button style={{marginLeft: 10}} variant='raised' size='small' onClick={(event)=>this.setState({selectedDate:'default'})}>show all</Button>
            </Typography>)}            
            <Table className={classes.table} padding="dense">
                <TableHead>
                    <TableRow className={classes.row}>
                        <TableCell>ID</TableCell>
                        <TableCell>Compliance Requirement</TableCell>
                        <TableCell>Risk Rating</TableCell>
                        <TableCell style={{textAlign: 'center'}}>Status</TableCell>
                        <TableCell>Due date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* { showComplelted ?
                    <TableRow className={classes.row}>
                        <TableCell>ID-LS-002</TableCell>
                        <TableCell>Requirements and restrictions of who can be engaged as a worker</TableCell>
                        <TableCell>High</TableCell>
                        <TableCell style={{background: "#009a44", color: "white"}}>Completed</TableCell>
                        <TableCell>N/A</TableCell>
                    </TableRow> : null
                    } */}
                    {(['default','due1week','due1month'].includes(selectedDate) || Math.floor(moment(selectedDate).diff(due_one_week_date, 'days', true))===0) && openAlerts.includes('ID-LS-003') ?
                    <TableRow className={classes.row} onClick={event => this.handleClickAlert(event, 'indonesia', 'ID-LS-003')} className={classes.clickable}>
                        <TableCell>ID-LS-003</TableCell>
                        <TableCell>Legal protections against discrimination</TableCell>
                        <TableCell>Low</TableCell>
                        <TableCell>
                            <Chip label='Open' style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                        <Tooltip title='due in 1 week'>
                        <TableCell>{due_one_week_date.format('DD-MM-YYYY')}</TableCell>
                        </Tooltip>                       
                    </TableRow> : null
                    }
                    {(['default','overdue'].includes(selectedDate) || Math.ceil(moment(selectedDate).diff(overdue_date, 'days', true))===0) && openAlerts.includes('ID-LS-004') ?
                    <TableRow className={classes.row} onClick={event => this.handleClickAlert(event, 'indonesia', 'ID-LS-004')} className={classes.clickable}>
                        <TableCell>ID-LS-004</TableCell>
                        <TableCell>Requirements for foreign employees</TableCell>
                        <TableCell>Medium</TableCell>
                        <TableCell>
                            <Chip label='Overdue' style={{background: setting.COLOR.RED, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                        <Tooltip title='overdue for 3 days'>
                        <TableCell>{overdue_date.format('DD-MM-YYYY')}</TableCell>
                        </Tooltip>
                    </TableRow> : null
                    }
                     {(['default','due1month'].includes(selectedDate) || Math.ceil(moment(selectedDate).diff(due_12_day_date, 'days', true))===0) && openAlerts.includes('ID-FX-001') ?
                    <TableRow className={classes.row} onClick={event => this.handleClickAlert(event, 'indonesia', 'ID-FX-001')} className={classes.clickable}>
                        <TableCell>ID-FX-001</TableCell>
                        <TableCell>Restrictions and controls on foreign exchange and cross-border remittances</TableCell>
                        <TableCell>Medium</TableCell>
                        <TableCell>
                            <Chip label='Open' style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                        <Tooltip title='due in 1 month'>
                        <TableCell>{due_12_day_date.format('DD-MM-YYYY')}</TableCell>
                        </Tooltip>
                    </TableRow> : null
                    }
                    {(['default','due1month'].includes(selectedDate) || Math.ceil(moment(selectedDate).diff(due_25_day_date, 'days', true))===0) && openAlerts.includes('ID-FX-002') ?
                    <TableRow className={classes.row} onClick={event => this.handleClickAlert(event, 'indonesia', 'ID-FX-002')} className={classes.clickable}>
                        <TableCell>ID-FX-002</TableCell>
                        <TableCell>Restrictions and controls on foreign loans and repayments of such foreign loans (Offshore Debt Plan Report)</TableCell>
                        <TableCell>Medium</TableCell>
                        <TableCell>
                            <Chip label='Open' style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                        <Tooltip title='due in 1 month'>
                        <TableCell>{due_25_day_date.format('DD-MM-YYYY')}</TableCell>
                        </Tooltip>
                    </TableRow> : null
                    }
                </TableBody>
            </Table>
            </div>
        );

        return (
            <div>
                <Grid container alignItems="center" justify='center' spacing={8} style={{width:"100%", margin:'0'}}>                   
                    <Grid item md={9} className={classes.chart}>
                        <Card style={{height:'100%'}}>
                            {/* <Typography variant="Headline">Upcoming Compliance</Typography> */}
                                {calendar}
                        </Card>
                    </Grid>
                    <Grid item md={3} className={classes.chart}>
                    <Card style={{height:'100%'}}>
                        <Grid container alignItems="center" justify='space-evenly' style={{height:"100%"}}>
                            <Grid item md={11} style={{color:setting.COLOR.RED, cursor:'pointer'}} onClick={(event)=>this.setState({selectedDate: 'overdue'})}>
                            <Typography variant="h3" style={{color:setting.COLOR.RED}} >1</Typography>
                            <Grid container>
                                {/* <Grid item md={1}>
                                <div style={{
                                        width: '100%',
                                        height: '20px',
                                        //   -webkit-border-radius: '25px';
                                        //   -moz-border-radius: '25px';
                                        borderRadius: '25px',
                                        background: 'red',
                                }}></div>
                                </Grid> */}
                                <Grid item md={12}>
                                    <Typography variant="subtitle1" style={{color:setting.COLOR.RED}} >due today or overdue</Typography>                               
                                </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} style={{color:setting.COLOR.YELLOW, cursor:'pointer'}} onClick={(event)=>this.setState({selectedDate: 'due1week'})}>
                            <Typography variant="h3" style={{color:setting.COLOR.YELLOW}}>1</Typography>
                            <Typography variant="subtitle1" style={{color:setting.COLOR.YELLOW}} >due in one week</Typography>                           
                            </Grid>
                            <Grid item md={12} style={{color:setting.COLOR.OLIVE, cursor:'pointer'}} onClick={(event)=>this.setState({selectedDate: 'due1month'})}>
                            <Typography variant="h3" style={{color:setting.COLOR.OLIVE}}>3</Typography>
                            <Typography variant="subtitle1" style={{color:setting.COLOR.OLIVE}} >due in one month</Typography>                            
                            </Grid>
                        </Grid>
                    </Card>    
                    </Grid>
                    <Grid item md={12}>
                        <Card>
                            {requirement_detail}
                        </Card> 
                        
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(Dashboard));