import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {HorizontalBar} from 'react-chartjs-2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Loader from 'react-loader-spinner';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import config from "../../config/config";
import setting from "../../config/setting";
import axios from 'axios';

const styles = theme => ({
    card: {
        //maxWidth: 345,
        height: "100%",
        // overflowY: "auto",
    },
    chart: {
        // minHeight: "200px",
        // height: "30vh",
    },
    content: {
        height: "100%",
    },
    card_table: {
        height: "100%",
        overflowY: "auto",
    },
    // transaction: {
    //     backgroudColor: "rgba(188,32,75,1)",
    //     color: "snow"
    // },
    // cardAction: {
    //     width: "100%",
    //     height: "100%"
    // },
    media: {
        // ⚠️ object-fit is not supported by IE11.
        objectFit: 'cover',
    },
    clickable: {
        cursor: 'pointer',
    },
});
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const today = new Date();
const country_options = ["all","singapore","indonesia","malaysia","vietnam"];
const category_options = ["all","corporate law","foreign exchange","labour & social security","tax", "Data Privacy and Protection Laws"];
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: this.props.country && country_options.includes(this.props.country.toLowerCase())? this.props.country.toLowerCase() : "all",
            selectedCategory: "all",
        }
    }
    groupBy = (xs, key) => {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };
    handleChangeFilter= event =>{
        this.setState({[event.target.name] : event.target.value, compliance_data:null}, () => this.getAlerts());
    }
    getAlerts(){
        function compareByValue(value){
            return function compare(a,b) {
                if (a[value] < b[value])
                return -1;
                if (a[value] > b[value])
                return 1;
                return 0;
            }
        }

        const {selectedCountry,selectedCategory} = this.state;

        axios.get(config.allAlerts)
        .then(res => {
            let alerts = res.data.filter(item => item.country.toLowerCase() === selectedCountry || selectedCountry === 'all');
            //group by category name
            let alerts_group_by_category = this.groupBy(alerts, 'category');
            //sort by compliance id
            for (var key in alerts_group_by_category)
            {
                alerts_group_by_category[key].sort(compareByValue('compliance_id'));
            }
            // console.log(selectedCountry);
            // console.log(alerts);
            // console.log(alerts_group_by_category);

            this.setState({
                compliance_data: alerts_group_by_category,
            });

            // console.log("in dashboard");
            // console.log(alerts_group_by_category);

            // let data={
            //     labels: [
            //     'Open',
            //     'Investigating',
            //     'Pending Review',
            //     'Pending Approval For Closure',
            //     'Closed'
            //     ],
            //     datasets:[{
            //         data:alert_data,
            //         backgroundColor: [
            //             '#FF6384',
            //             '#36A2EB',
            //             '#FFCE56',
            //             '#990099',
            //             '#ff9900'
            //             ],
            //             hoverBackgroundColor: [
            //             '#FF6384',
            //             '#36A2EB',
            //             '#FFCE56',
            //             '#FF6384',
            //             '#36A2EB'
            //             ]
            //     }]
            // }
            // this.setState({
            //     alertStatusData: data
            // })
        })      
        .catch(err => {
            console.log(err);
          });
    }

    componentDidMount(){
        this.getAlerts();
    }

    componentWillMount() {
        // setInterval(() => {
        //     // this.setState(getState());
        //     this.getAlerts();
        //     this.getTasks();
        // }, 60000);
    }
    handleClickAlert = (e, country, id) =>{
        this.props.history.push('/' + country.toLowerCase() + '?searchVal=' + id.toLowerCase());
    }
    render() {
        const { classes,  } = this.props;
        const { compliance_data, } = this.state;
        if (compliance_data == null) {
            return (
            <Grid container justify = "center" style={{height: '90%'}} alignItems="center">
                <Loader 
                    type="Oval"
                    color={setting.COLOR.MEDIUM_BLUE}
                    height="50"	
                    width="50"
                />
            </Grid>
            );
        }
        // console.log(compliance_data);
        const keys = Object.keys(compliance_data);
        // console.log(keys);
        const count_total = [];
        keys.forEach(key=>{
            count_total.push(compliance_data[key].length);
        });
        // console.log(count);
        const data = {
            labels: keys,
            datasets: [
              {
                label: 'compliance',
                backgroundColor: setting.COLOR.MEDIUM_BLUE,
                borderColor: setting.COLOR.MEDIUM_BLUE,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: count_total
              }
            ],
          };
        const data_non_compliance = {
            labels: ['Corporate Law', 'Foreign Exchange', 'Labour & Social Security', 'Tax', 'Data Privacy and Protection Laws'],
            datasets: [
                {
                label: 'Low',
                backgroundColor: setting.COLOR.GREEN,
                borderColor: setting.COLOR.GREEN,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [0, 0, 1, 0, 0]
                },
                {
                label: 'Medium',
                backgroundColor: setting.COLOR.YELLOW,
                borderColor: setting.COLOR.YELLOW,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [0, 1, 1, 0, 0]
                },
                {
                label: 'High',
                backgroundColor: setting.COLOR.RED,
                borderColor: setting.COLOR.RED,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [0, 0, 1, 0, 0]
                },
            ],            
        };

        const data_compliance_non_compliance = {
            labels: ['High', 'Medium', 'Low'],
            datasets: [
              {
                label: 'Compliance',
                backgroundColor: setting.COLOR.GREEN,
                borderColor: setting.COLOR.GREEN,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [26, 11, 40]
              },
              {
                label: 'Non-compliance',
                backgroundColor: setting.COLOR.RED,
                borderColor: setting.COLOR.RED,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [1, 2, 1]
              }
            ],            
        };
        let data_requirement_detail = [];
        data_requirement_detail.push(compliance_data['Labour & Social Security'][0]);
        data_requirement_detail.push(compliance_data['Labour & Social Security'][1]);
        data_requirement_detail.push(compliance_data['Labour & Social Security'][2]);
        data_requirement_detail.push(compliance_data['Foreign Exchange'][0]);
        // console.log(data_requirement_detail);

        const requirement_detail = (
            <div>
            <Typography variant="subtitle1">Instances of NON-COMPLIANCE</Typography>
            <Table padding="dense">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Compliance Requirement</TableCell>
                        <TableCell style={{textAlign: 'center'}}>Risk Rating</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow onClick={event => this.handleClickAlert(event, this.state.selectedCountry, data_requirement_detail[0].compliance_id)} className={classes.clickable}>
                        <TableCell>{data_requirement_detail[0].compliance_id}</TableCell>
                        <TableCell>{data_requirement_detail[0].compliance_requirement}</TableCell>
                        <TableCell>
                            <Chip label='High' style={{background: setting.COLOR.RED, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                    </TableRow>
                    <TableRow onClick={event => this.handleClickAlert(event, this.state.selectedCountry, data_requirement_detail[1].compliance_id)} className={classes.clickable}>
                        <TableCell>{data_requirement_detail[1].compliance_id}</TableCell>
                        <TableCell>{data_requirement_detail[1].compliance_requirement}</TableCell>
                        <TableCell>
                            <Chip label='Low' style={{background: setting.COLOR.GREEN, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                    </TableRow>
                    <TableRow onClick={event => this.handleClickAlert(event, this.state.selectedCountry, data_requirement_detail[2].compliance_id)} className={classes.clickable}>
                    <TableCell>{data_requirement_detail[2].compliance_id}</TableCell>
                        <TableCell>{data_requirement_detail[2].compliance_requirement}</TableCell>
                        <TableCell>
                            <Chip label='Medium' style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                    </TableRow>
                    <TableRow onClick={event => this.handleClickAlert(event, this.state.selectedCountry, data_requirement_detail[3].compliance_id)} className={classes.clickable}>
                        <TableCell>{data_requirement_detail[3].compliance_id}</TableCell>
                        <TableCell>{data_requirement_detail[3].compliance_requirement}</TableCell>
                        <TableCell>
                            <Chip label='Medium' style={{background: setting.COLOR.YELLOW, color: "white", width: "100%"}}></Chip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </div>
        );

        return (
            <div style={{height: "90%"}} >
                <Grid container alignItems="center" justify='center' spacing={8} style={{width:"100%", margin:'0'}}>
                    <Grid item md={4}>
          <InputLabel htmlFor="selectedCountry">Country</InputLabel>
          <Select
            value={this.state.selectedCountry}
            style={{textTransform: 'uppercase', minWidth: '200px'}}
            onChange={this.handleChangeFilter}
            inputProps={{
              name: 'selectedCountry',
              id: 'selectedCountry',
            }}
          >
          {country_options.map(option=>{
              return (<MenuItem value={option} style={{textTransform: 'uppercase'}}>{option}</MenuItem>);
          })}
          </Select>
          </Grid>
          <Grid item md={4}>
          {/* <InputLabel htmlFor="selectedCategory">Category</InputLabel>
          <Select
            value={this.state.selectedCategory}
            style={{textTransform: 'uppercase', minWidth: '200px'}}
            onChange={this.handleChangeFilter}
            inputProps={{
              name: 'selectedCategory',
              id: 'selectedCategory',
            }}
          >
          {category_options.map(option=>{
              return (<MenuItem value={option} style={{textTransform: 'uppercase'}}>{option}</MenuItem>);
          })}
          </Select> */}
                    </Grid>
                    <Grid item md={6} className={classes.chart}>
                        <Card className={classes.card}>
                            <CardContent className={classes.content}>
                                <Typography variant="subtitle1"><b>{count_total.reduce((accumulator, currentValue) => accumulator + currentValue)} </b> Compliance Requirements</Typography>
                                <HorizontalBar data={data}
                                    height="100%"
                                    options={{
                                        legend: {
                                            display:false,
                                        },
                                        scales: {
                                            xAxes: [{
                                                display: false,
                                                stacked: true,
                                                // ticks:{
                                                //     beginAtZero: true,
                                                // },
                                                // gridLines: {
                                                //     display:false,
                                                //     drawBorder: false,
                                                    
                                                // }    
                                            }],
                                            yAxes:[{
                                                gridLines: {
                                                    display:false
                                                },
                                            }],
                                        },
                                }}/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} className={classes.chart}>
                        <Card className={classes.card}>
                            <CardContent className={classes.content}>
                                <Typography variant="subtitle1">NON-COMPLIANCE by Risk Rating</Typography>
                                <HorizontalBar data={data_non_compliance}
                                height="100%"
                                options={{
                                    legend: {
                                        display:true,
                                    },
                                    scales: {
                                        xAxes: [{
                                            display: false,
                                            stacked: true,
                                            // ticks:{
                                            //     beginAtZero: true,
                                            // },
                                            // gridLines: {
                                            //     display:false,
                                            //     drawBorder: false,
                                                
                                            // }   
                                        }],
                                        yAxes:[{
                                            stacked: true,
                                            gridLines: {
                                                display:false
                                            } 
                                        }],
                                    }
                                }}/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        <Card className={classes.card_table}>
                            <CardContent className={classes.table}>
                                {requirement_detail}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} className={classes.chart}>
                        <Card>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography variant="subtitle1">Compliance Status by Risk Rating</Typography>
                                        <HorizontalBar data={data_compliance_non_compliance} 
                                            height="80%"
                                            options={{
                                            legend: {
                                                display:true,
                                            },
                                            scales: {
                                                xAxes: [{
                                                    display: false,
                                                    stacked: true,
                                                    // ticks:{
                                                    //     beginAtZero: true,
                                                    // },
                                                    // gridLines: {
                                                    //     display:false,
                                                    //     drawBorder: false,
                                                        
                                                    // }   
                                                }],
                                                yAxes:[{
                                                    stacked: true,
                                                    gridLines: {
                                                        display:false
                                                    } 
                                                }],
                                            }
                                        }}/>
                                    </CardContent>
                                </div>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(Dashboard));