import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import { Divider } from '@material-ui/core';
import TransactionTable from "../Transactions/TransactionTableMin";
import ChipInput from 'material-ui-chip-input'
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//dialog related import
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DescriptionTable2 from '../Entities/DescriptionTable/DescriptionTable2';
import AlertChip from "../AlertChip";

const styles = theme => ({
    card: {
        width: "100%",
        height: '75vh',
    },
    header: {
        textAlign: "left"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
        height: 56
    },
    content: {
        textAlign: "left",
        height: '55vh',
        overflow: "auto"
    },
    actionContent: {
        textAlign: "left",
        height: '30vh',
        overflow: "auto"
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    transaction: {
        paddingTop: 10,
        overflow: "auto",
        height: "10vh"
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        //backgroundColor: red[500],
    },
});

class RecipeReviewCard extends React.Component {
    state = {
        expanded: false,
        actionView: false,
        chips: [],
        open: false,
        value: 0,
        headers: ["Address", "Tel", "Email", "CreatedBy", "Flags"],
        descriptionData: {
            Address: "1232",
            Tel: "342342",
            Email: "dfasddfs",
            CreatedBy: "fdsdfadf",
            Flags: [{
                name: "Same Director",
                color: "high"
            },{
               name: "Duplicate Address",
               color: "mid" 
            }]    
        }
    };


    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };
    handleViewToggle = () => {
        this.setState(state => ({ actionView: this.state.actionView ? false : true }));
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    //for tabs
    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const header = (
            <div>
                <span>British Land Authority</span>
            </div>
        )
        const testEmails = ["yongyibian@kpmg.com", "elisa@kpmg.com", "yuri@kpmg.com", "jiajie@kpmg.com", "koonhan@kpmg.com"]
        const inputHeader = (
            <ChipInput
                hintText='Assignees Email'
                dataSource={testEmails}
                fullWidth
            />
        )
        const dialog = (<Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Action confirmaton"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Proceed with your action?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="secondary">
                    Cancel
              </Button>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                    Yes
              </Button> 
            </DialogActions>
        </Dialog>
        )
        const hardCodedChips = (
            <span>
                <AlertChip
                    label="Conflict Interest"
                    color="mid"
                />
                <AlertChip
                    label="Duplicate Address"
                    color="mid"
                />

            </span>
        )
        const actionView = (
            <Card className={classes.card}>
                <CardActions className={classes.actions}>
                    <Button variant="outlined" size="small" color="secondary" onClick={this.handleViewToggle}>Discard</Button>
                </CardActions>
                <Divider />
                <CardHeader
                    avatar={
                        <Button variant="outlined" size="medium" color="primary">
                            <EmailIcon />
                        </Button>
                    }
                    title={inputHeader}
                    className={classes.header}
                />
                <Divider />
                <CardHeader
                    avatar={
                        <Avatar aria-label="Recipe" className={classes.avatar}>
                           SLA
                        </Avatar>
                    }
                    action={
                        null
                    }
                    title={header}
                    subheader="September 14, 2016"
                    className={classes.header}
                />
                <Divider />
                <CardContent className={classes.actionContent}>
                    <TextField
                        id="full-width"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        multiline
                        placeholder="Body"
                        helperText="Please input reasons or instructions before you dispatch this alert to another person in charge"
                        fullWidth
                        margin="normal"
                    />
                </CardContent>
            </Card>
        );
        const detailsView = (
            <Card className={classes.card}>

                <CardHeader
                    // avatar={
                    //     <Avatar aria-label="Recipe" className={classes.avatar}>
                    //         <img src="https://www.sla.gov.sg/Portals/0/Images/SLA_logo-thumb.png?ver=2013-10-28-143344-000"/>
                    //     </Avatar>
                    // }
                    action={
                        null
                    }
                    title={header}
                    subheader="The British Land Authority is a statutory board under the Ministry of Law of the British Government."
                    className={classes.header}
                />
                <Divider/>
                <CardContent className={classes.content}>
                    <div>
                        <Typography variant="title">Master Information</Typography>
                        <DescriptionTable2 />
                        <div style={{height:"2vh"}}></div>
                    </div>
                    <div>
                        <Typography variant="title">Flagged Transactions</Typography>
                        <Tabs
                            value={this.state.value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChange}
                        >
                            <Tab label="Purchase Order" />
                            <Tab label="Invoices" />
                        </Tabs>
                        <Divider />
                        {this.state.value == 0 ? <TransactionTable /> : null}
                        {this.state.value === 1 ? <TransactionTable /> : null}
                        <Divider />
                    </div>
                </CardContent>
            </Card>
        );
        return (
            <div>
                {this.state.actionView ? actionView : detailsView}
                {dialog}
            </div>
        );
    }
}

RecipeReviewCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);