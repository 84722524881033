import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider } from '@material-ui/core';
import EntityTransactionTable from "../DescriptionTable/EntityTransactionTable";

import axios from 'axios';

class TabTransactionTable extends React.Component {

    render(){
        //console.log("this is in TabTransactionTable")
        //console.log(this.props.data.transactions)         
        const transactions = this.props.data.transactions
        var defaultValue;
        for (var prop in transactions) {
            // object[prop]
            defaultValue = prop
            break;
        }
        const value = this.props.config.value == 0 ? defaultValue : this.props.config.value;
        return (
            <div>
            <Typography variant="title">Flagged Transactions</Typography>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.props.onTabChange}
            >
            {Object.keys(transactions).map(function(keyName, keyIndex) {
                // use keyName to get current key's name
                // and a[keyName] to get its value
                // console.log(keyName);
                // console.log(transactions[keyName]);
                return <Tab label={keyName} value={keyName}/>
            })}
                {/* <Tab label="Purchase Order" />
                <Tab label="Invoices" /> */}
            </Tabs>
            <Divider />
            <EntityTransactionTable type={value} transactions={transactions[value]}/>
            <Divider />
        </div>
        )
    }
}

// export default withStyles(styles)(TabTransactionTable);
export default TabTransactionTable;