import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import AlertComponent2 from "./Routines/ListDetail";
import Slide from '@material-ui/core/Slide';
import {CapitalizeFirstLetter} from '../util/StringUtil';

import setting from "../config/setting";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class AlertChip extends Component {
  state = {
    open: false,
    count: this.props.count == null ? 0 : this.props.count,
    queryID: this.props.label
  };

  handleClickOpen = () => {
    if(this.state.count < 1 ){
        this.setState({ open: true });
    }else{
        console.log("label is  "+this.props.label);
        console.log(this.props.taskRefresh);
        this.setState({ queryID: this.props.label });
        this.props.taskRefresh(this.props.label);
    }
  };

  handleClose = () => {
    this.setState({ open: false });
/*    this.setState({ count: 0 });*/
  };
  handleClick = () => {
    console.log("chip clicked");
  }
  render() {
    //console.log("metadata in alert chip")
    //console.log(this.props.metadata);
    let kpmgTheme = {
      high: setting.COLOR.RED,
      high2: "rgba(188,32,75,1)",
      mid: setting.COLOR.YELLOW,
      mid2: "rgba(246,141,46,1)",
      low: setting.COLOR.GREEN,
      low2: "rgba(67,176,42,1)",
      transparent: "rgba(0,0,0,0)"
    }

    const dialog = (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar style={{backgroundColor:"rgb(0,145,218)"}}>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <LeftIcon />
            </IconButton>
            <Typography variant="title" color="inherit" >
              {this.props.metadata == null ? "no metadata" : CapitalizeFirstLetter(this.props.metadata.name)}
              </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{position: "relative", width: "45vw", marginTop: "10vh"}}>
          <AlertComponent2 
          count={this.state.count + 1}
          style={{ width: "100vw" }}
          username={this.props.username}
          queryID={this.state.queryID}/>
        </DialogContent>

      </Dialog>
    )
    return (
      <span>
        {dialog}
        <Tooltip title="Flagged on: 09/09/2018" placement="top" >
          <Chip
            label={this.props.label}
            style={{ background: this.props.avatar ? (this.props.TLight ? kpmgTheme.transparent : kpmgTheme[this.props.color]) : kpmgTheme[this.props.color + "2"], color: "snow" }}
            avatar={this.props.avatar ? <Avatar
              style={{ background: kpmgTheme[this.props.color + "2"], color: "snow" }}
            >{this.props.avatar}</Avatar> : null}
            onClick={this.handleClickOpen}
            variant={this.props.outlined ? "outlined" : null}
          />
        </Tooltip>
      </span>
    )
  }
}

export default AlertChip;