import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TransactionTable from "./TransactionTable";
import TransactionTableInvoice from "./TransactionTableInvoice";
import AlertChip from "../AlertChip";
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightRegular,
    },
    detailsRoot: {
        width: '100%',
    }
});

function TransactionAlerts(props) {
    const { classes } = props;
    // console.log(props)
    let type = props.type;
    // console.log("in transaction alerts")
    // console.log(type)
    const chips = (<span style={{ marginLeft: 10, width: "30vw", textAlign: "center" }}>
        <AlertChip
            label="high"
            color="high"
            avatar="22"
        />
        <AlertChip
            label="mid"
            color="mid"
            avatar="21"
        />
        <AlertChip
            label="low"
            color="low"
            avatar="12"
        /></span>);
    return (
        <div className={classes.root}>
            <Paper style={{minHeight:"80vh"}}>
                {type =="invoice" ? <TransactionTableInvoice token={props.token}/> : <TransactionTable token={props.token} type={type}/>}
            </Paper>
        </div>
    );
}

TransactionAlerts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransactionAlerts);