import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {theme} from '../CSS/CommonStyles';
import Spacing from './Spacing';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormControl';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AttachFile from '@material-ui/icons/AttachFile';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';

import setting from "../../config/setting";
import config from "../../config/config";
import axios from 'axios';

theme.spacing = Spacing;

function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© 2019 KPMG Services Pte. Ltd. (Registration No: 200003956G), a Singapore incorporated company and a member firm of the KPMG network of independent member firms affiliated with KPMG International Cooperative ("KPMG International"), a Swiss entity. All rights reserved.'}
    </Typography>
  );
}

const styles = {
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up('sm')]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textAlign: 'left',
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
};

const demo_task = {
  id: 1,
  description: "Age requirements",
  created_date: moment().add("days",-7).format(setting.date_format),
  due_date: moment().add("days",5).format(setting.date_format),
  status: "Completed",
  comment: "",
  created_user: 1,
  created_user_name: "adminA",
  assigned_user: 5,
  assigned_user_name: "staffB",
  alert:1,
  routine:"Labour & Social Security",
  questions: [
      {
          id: 1,
          question_type: "Boolean",
          question_text: "Have age requirements for employees been met?",
          response: "True",
          attachments:[],
      },
      {
          id: 2,
          question_type: "Attachment",
          question_text: "Evidence that age requirement has been met for all new hires",
          response: "",
          attachments:[{
              file_name: "sample compliance form.xlsx",
              size: "6167",
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },],
          attachment_id:18,
      },
  ],
};

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
        scroll: 'paper',
        tasks: demo_task,
        loading: true,
    };
  }

  componentDidMount(){
    this.getQuestions();
    if (sessionStorage.getItem("token")) {
      axios.defaults.headers.common['Authorization'] = 'token ' + sessionStorage.getItem("token");
    }
  }

  getQuestions = ()=>{
    const search =this.props.location.search;
    let taskid = 1;
    if (search) {
      const params = new URLSearchParams(search);
      const id = params.get('id');
      taskid = id;
    }
    let endpoint = config.taskRespondent(taskid);
    //console.log(endpoint);
    axios.get(endpoint).then(
      res => {
          // console.log("update task template with new data");
          // console.log(res);
          this.setState({loading: false});
          if (res.data)
          {
            let status = res.data.status;
            if (status == "Pending Response")
            {
              this.setState({
                  tasks: res.data,
                  loading: false,
              });
            } else {
             // expired link
             this.props.history.push('/http404');
            }
          }
      }
    ).catch(
        error => {
            console.log(error);
            console.log("error");
            if (error.response.status == '401')
            {
                this.props.history.push('/login');
            }
            else {
                this.props.history.push('/http404');
            }
        }
    );
  }

  handleResponseOnChange = (e, id) =>{
    // console.log(e.target.value + " in " +id)
    let updateObj = this.state.tasks.questions.find(o=>o.id == id);
    let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);
    if(e.target.name === "response"){
        // console.log(updatequestionIndex)
        updateObj.response = e.target.value;
        this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
        this.forceUpdate();
    } else if (e.target.name === "reason")
    {
        // console.log(updatequestionIndex)
        updateObj.reason = e.target.value;
        this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
        this.forceUpdate();
    }
  };

  ResponseFileUpdateRequest = (formData) => {
      return axios.post(config.attachment, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      }).then((res)=>{
          console.log("upload file res");
          console.log(res);
          //  this.setState({ tempID: res.data.id });
          return res.data.id;
      })
          .catch(err=>{ console.log(err.response); });
  };

  handleResponseAttachmentOnChange = (e, id) =>{
      let updateObj = this.state.tasks.questions.find(o=>o.id == id);
      let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);

      let attachment = e.target.files[0];

      var formData = new FormData();
      var uploads = attachment;
      // console.log(uploads);
      formData.append("file", uploads);
      // console.log ("formdata");
      // console.log(formData);

      this.ResponseFileUpdateRequest(formData).then(data => {
          // console.log(data)
          updateObj.attachment_id = data;
          updateObj.attachments.push(uploads);
          console.log("updateobj");
          console.log(updateObj);
          this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
          this.forceUpdate();
      });        
  };

  ResponsePutRequest = (postObj) => {
      return axios.put(config.tasksSub(this.state.tasks.id),postObj)
          .then(res=>{
              console.log("this is the result of post request");
              console.log(res)})
          .catch(error=>{console.log(error.response)});
      
  };

  handleResponseSubmit = () => {
      let postObj = this.createResponseSubmitObj();
      this.ResponsePutRequest(postObj).then(res => {
          // this.props.refreshTaskList();
          // this.setState({ open: false });
          // this.props.close();
      });
  };
  createResponseSubmitObj(){
      let submitObj = {};
      submitObj.id = this.state.tasks.id;
      submitObj.questions = this.state.tasks.questions;
      console.log("submit response obj");
      console.log(submitObj);
      return submitObj;
  };
  handleRadioGroupChange = (e, id) => {
      // console.log("in radio change")
      // console.log(id);
      let updateObj = this.state.tasks.questions.find(o=>o.id === id);
      // console.log(updateObj)
      let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);
      updateObj.response = e.target.value;
      this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});

      this.forceUpdate();
  };

  renderQuestion(t){
    // console.log("in question")
    // console.log(this.state.tasks.questions[t.id-1].response)
    // console.log(t)
    switch (t.question_type.toLowerCase())
    {
        case "attachment":
        return (
        <span>
            <input
                hidden
                id={"uploadFileButtonForResponse" + t.id }
                accept=".xls,.xlsx,.pdf,.doc,.docx,image/*"
                // multiple 
                type="file"
                name={"attachemnt_id"}
                onChange = {(e) => {this.handleResponseAttachmentOnChange(e, t.id)}}
            />
            <label htmlFor={"uploadFileButtonForResponse" + t.id}>
                <Button variant="contained" component="span" size="small" >
                <AttachFile />
                Upload
                </Button>
                {t.attachments.length>0 ? <span style={{marginLeft:"1vw"}}>{t.attachments[0].name}</span>: null}
            </label>
        </span>
        );
        case "numbers":
        return (
        <TextField
            margin="dense"
            id="name"
            placeholder="your response here"
            fullWidth
            name = {"response"}
            type = "number"
            onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
        />
        );
        case "boolean":
        return (
            <div style={{marginLeft: "2vw"}}>
                <FormGroup>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            value={t.response}
                            onChange={(e) => {this.handleRadioGroupChange(e, t.id)}}
                            row
                            required="required"
                            style={{ display: 'inline-flex' }}
                        >
                            <FormControlLabel value="True" control={<Radio />} label="Yes" />
                            <FormControlLabel value="False" control={<Radio />} label="No" />
                            <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>    
                {t.response == "N/A" ? 
                <div style={{width: '90%'}}><FormGroup style={{width: '100%'}}>
                    <FormControl style={{width: '100%'}}>
                        <TextField
                            margin="dense"
                            id="name"
                            placeholder="reasons for selecting N/A"
                            fullWidth
                            multiline                           
                            type="text"
                            name = {"reason"}
                            onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
                        />
                    </FormControl>
                </FormGroup></div> : null}
            </div>
            );
        case "date":
        return (
            <TextField
                margin="dense"
                id="name"
                placeholder="your response here"
                fullWidth
                type="date"
                name = {"response"}
                onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
            />
            );
        case "text":
        return (
            <TextField
                margin="dense"
                id="name"
                placeholder="your response here"
                fullWidth
                name = {"response"}
                onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
            />
            );
        default:
        return (
            <TextField
                margin="dense"
                id="name"
                placeholder="your response here"
                fullWidth
                name = {"response"}
                onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
            />
            );
    }
  }

  render(){
    const {classes} = this.props;
    const {loading} = this.state;
    let question_index = 0;
    const responseView = loading ?
      <Grid container justify="center" align="center">
          <Grid item>
              <Loader 
                  type="Oval"
                  color={setting.COLOR.MEDIUM_BLUE}
                  height="50"	
                  width="50"
              />
          </Grid>
      </Grid>
      :
    (<div>
      <div style={{ height: "1vh" }}></div>
      {this.state.tasks.questions
          .sort((a,b)=>(a.id > b.id) ? 1 : ((b.id > a.id)? -1 : 0))
          .map(t => {
              // console.log(t);
              question_index ++;
              return (
                  <div key = {t.id}>
                      <Typography variant="subheading">{t.question_text + "*"}</Typography>
                      {this.renderQuestion(t)}                          
                      <div style={{ height: "2vh" }}></div>
                  </div>
              )
          })
      }
  </div>);

    return (
      <React.Fragment>
        <CssBaseline />
        {/* <AppBar position="relative" color="primary" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap align="left">
              KPMG Singapore
            </Typography>
          </Toolbar>
        </AppBar> */}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              AIM Task
            </Typography>
            {responseView}
            <Grid container justify="flex-end">
              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleResponseSubmit}>Submit</Button>
            </Grid>
          </Paper>
          <Footer />
        </main>
      </React.Fragment>
    );
  }
}

Survey.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Survey));