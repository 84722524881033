import warning from 'warning';

const unit = 8;

const spacing = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    warning(
      args.length <= 4,
      `Too many arguments, exepected 1..4, got ${args.length}`,
    );

    args.forEach((arg, index) => {
      warning(
        typeof arg === 'number',
        `Expected argument ${index + 1} to be a number, got ${arg}`,
      );
    });
  }

  return args
    .map((multiplier = 0) => multiplier && `${unit * multiplier}px`)
    .join(' ');
};

spacing.unit = unit;

export default spacing;