import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import ListDetail from "./ListDetail/ListDetail";
//searchBar related import
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedTableToolbar from "./SharedComponents/EnhancedTableToolbar";
import EnhancedTableHead from "./SharedComponents/EnhancedTableHead";

let counter = 0;
function createData(name, flags, actions, carbs, protein) {
    counter += 1;
    return { id: counter, name, flags, actions, carbs, protein };
}


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
        height: '75vh'
    },
    contentWrapper: {
        height: '100%',
    },
    margin: {
        height: 56,
        marginLeft: theme.spacing.unit,
        padding: 8,
    },
});

class VendorAlertTable extends React.Component {
    state = {
        open: true,
        anchor: 'right',
        order: 'asc',
        orderBy: 'name',
        details: true,
        list: 4,
        selected: [],
        data: [
            createData('Singapore Land Authority', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }, {
                name: "Duplicate Address",
                avatar: "DA",
                color: "primary"
            }, {
                name: "Duplicate PO",
                avatar: "DP",
                color: "secondary"
            }
            ], 3.7, 67, 4.3),
            createData('UBS', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }, {
                name: "Duplicate Address",
                avatar: "DA",
                color: "primary"
            }], 25.0, 51, 4.9),
            createData('Keppel', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }, {
                name: "Duplicate Address",
                avatar: "DA",
                color: "primary"
            }], 16.0, 24, 6.0),
            createData('Visa', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }, {
                name: "Duplicate PO",
                avatar: "DP",
                color: "secondary"
            }], 6.0, 24, 4.0),
            createData('Toyota Motor', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 16.0, 49, 3.9),
            createData('Royal Dutch Shell', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 3.2, 87, 6.5),
            createData('Apple', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 9.0, 37, 4.3),
            createData('Exxon Mobil', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 0.0, 94, 0.0),
            createData('Paypal Holdings', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 26.0, 65, 7.0),
            createData('Singapore Airlines', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 0.2, 98, 0.0),
            createData('Volkswagen', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 0, 81, 2.0),
            createData('Deutsche Bank', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 19.0, 9, 37.0),
            createData('P&G', [{
                name: "Confict Interest",
                avatar: "CI",
                color: "primary"
            }], 18.0, 63, 4.0),
        ],
        page: 0,
        rowsPerPage: 10,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleAction = event => {
        this.setState({ details: this.state.details ? false : true });
        this.setState({ list: this.state.list == 4 ? 12 : 4 });
    }
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, theme } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, anchor, open, details, list } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const detailsView = (<Grid item md={8} className={classes.contentWrapper}>
            <ListDetail />
        </Grid>);
        const searchBar = (<div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item>
                    <TextField id="input-with-icon-grid" label="Search..." fullWidth />
                </Grid>
            </Grid>
            <Divider />
        </div>);

        return (
            <Paper className={classes.root} style={{ height: "92vh" }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <Grid container padding>
                    <Grid item md={list}>
                        <Card className={classes.tableWrapper}>
                            {searchBar}
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {data
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            const isSelected = this.isSelected(n.id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    selected={isSelected}
                                                >
                                                    <TableCell padding="checkbox"
                                                        onClick={event => this.handleClick(event, n.id)}>
                                                        <Checkbox checked={isSelected} />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none" onClick={event => this.handleAction(event, n.id)}>
                                                        {n.name}
                                                    </TableCell>
                                                    <TableCell numeric>{n.flags.map(e => {
                                                        i++;
                                                        return (<Chip
                                                            key={i}
                                                            label={e.name}
                                                            color={e.color}
                                                            style={{ margin: 1 }}
                                                        />)
                                                    })}</TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                    {this.state.details ? detailsView : null}
                </Grid>
                <Divider />
                <TablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

VendorAlertTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorAlertTable);