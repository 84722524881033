import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {CapitalizeFirstLetter} from '../../util/StringUtil';
import Tooltip from '@material-ui/core/Tooltip';

import config from '../../config/config';

import {menuPadding} from '../CSS/CommonStyles';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 90,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    avatar: {
        width: 30,
        height: 30
    },
    menufont: {
    	"font-size": "20px",
    },
	menufontBold: {
        "font-size": "20px",
		"font-weight": "bold"
    },
    image: {
        flex: 1,
        alignSelf: 'stretch',
        width: "50%",
        height: "50%"
    }

});

let defaultpadding = 12;
let menulvlSpacing = 15;

class MenuItem extends React.Component{
  constructor(props)
  {
    super(props);
    this.state = {
        open: false,
    }
  }

  getChildren = (id) =>{
    if (this.props.raw && this.props.node.id)
    {
      return this.props.raw.filter(node=> node.parent_id == id)
    }
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  
  render(){
    const {node,children,raw, handleRoute, parentCollapse} = this.props
    // console.log(this.props.node.id)
    // console.log(this.props.children)
    let childnodes = null;
    let handleClicking = handleRoute.bind(this, node.name);
    let shouldCollapse = parentCollapse && this.state.open;
    let expandArrow = null;
    if (children && children.length > 0)
    {
        expandArrow = (this.state && this.state.open)? <ExpandLess /> : <ExpandMore />;
        handleClicking = this.handleClick;
        childnodes = children.map((childnode) =>
        {
        //   console.log("in child")
        let childs = raw.filter(node=> node.parent_id == childnode.id);
        //   console.log(childs)
        return (
            <MenuItem key={childnode.id} node={childnode} children={childs} raw={raw} handleRoute={handleRoute} parentCollapse={shouldCollapse}></MenuItem>
        )
        })
    }

    let childMenu = childnodes ? (
        <Collapse in={shouldCollapse} timeout="auto">
            <List>{childnodes}</List>
        </Collapse>
    ): null

    var item = null;
    if (node.parent_id)
    {
        // leaf
        item = (
        // <Collapse in={shouldCollapse} timeout="auto" unmountOnExit>       
            <Tooltip title={CapitalizeFirstLetter(node.name)}>
                <ListItem style={menuPadding(node.depth)} button onClick={handleClicking}>
                    {/* <ListItemText primary = {CapitalizeFirstLetter(node.name)}/> */}
                    <span style={{overflowX:'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px',}} >{CapitalizeFirstLetter(node.name)}</span>
                    {expandArrow}
                </ListItem>
            </Tooltip>
        // </Collapse>
        );
    } else{
        // top-level item
        item = (
        <Tooltip title={CapitalizeFirstLetter(node.name)}>
            <ListItem button onClick={handleClicking}>
                <span style={{        
        width: "12vw", 
        fontWeight: "bold",
        fontSize: "14px",}} >{CapitalizeFirstLetter(node.name)}</span>
                {expandArrow}
            </ListItem>
        </Tooltip>
        );
    }

    return (
    <div>
        {item}
        {childMenu}
    </div>
    //   <li key={this.props.node.id}>      
    //     <span>{this.props.node.name}</span>        
    //     { childnodes ?
    //       <ul>{childnodes}</ul>
    //     : null }
    //   </li>
    )
  }
}

MenuItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

MenuItem = withStyles(styles)(MenuItem);

class SideMenuList extends React.Component {
    state = {
        open: false,
        menuList: "",
        menuBEData: ""
    };

    componentDidMount(){
        // axios.get(config.menu).then(res=>{
        //     console.log("gotten menu");
        //     console.log(res);
        // 	this.setState({menuBEData : res.data.results})
        //     // this.setState({menuList : this.createList(res.data.results)})
        // }).catch(error => {
        //     console.log(error);
		// 	console.log(error.message);
		// 	console.log("error");
        // });
        fetch(config.menu).then(response => response.json()).then(res => {
            // console.log("gotten menu");
            // console.log(res);
        	this.setState({menuBEData : res.results})
        }).catch(error => {
			// console.log(error.message);
			// console.log("error");
        });
    }

    handleClick = () => {
        // console.log("test");
        this.setState(state => ({ open: !state.open }));
    };

    // createList = (list) =>{
	// 				let menuList = {};
	// 				let parentPath = "";
	// 				list.map(item => {
	// 					if (item.parent_name == null){
	// 						menuList[item.name]={};
	// 					} else if (menuList.hasOwnProperty(item.parent_name))	{
	// 						menuList[item.parent_name][item.name] = {};
	// 						parentPath = menuList[item.parent_name][item.name];
	// 					} else {
	// 						parentPath[item.name] = {};
	// 					}
	// 				});
	// 				return menuList;
    // };

    // menuViewJSXCreate = (list, menuViewList, lvl) =>{

    // 		const { classes } = this.props;

    // 		for(var key in list){
    // 				let parentName = this.state.menuBEData.find(o=>o.name == key);
    // 				// console.log(parentName);
    // 				// console.log(list[key]);
    // 				// console.log(Object.keys(list[key]).length == 0);
	// 					// {this.state.transactionsOpen ? <ExpandLess /> : <ExpandMore />}
						
   	// 				if(Object.keys(list[key]).length == 0  && parentName.parent_name != null){
   	// 						// Leaf

   	// 						// find its parent' parent if any
   	// 						let shouldcollapse = true;
   	// 						do{
   	// 							// if it's parent is collapsed ?
   	// 							shouldcollapse = shouldcollapse && this.state[parentName.parent_name];
   	// 							// find its parent's parent.
   	// 							parentName = this.state.menuBEData.find(o=>o.name == parentName.parent_name);
   	// 							//console.log(key+" should collapse after " + i + " iteration : "+shouldcollapse)
   	// 						}
   	// 						// continue if parent's parent still exist (trace to the root)
   	// 						while(parentName.parent_name != null)

	// 	    				const menuItem = (
	// 	    					<Collapse in={shouldcollapse} timeout="auto" unmountOnExit>
	// 	          					<ListItem style={{paddingLeft: (defaultpadding + menulvlSpacing * lvl) + "px"}} button onClick={this.props.handleRoute.bind(this, key)}>
	// 	          						<ListItemText primary = {CapitalizeFirstLetter(key)}/>
	// 	          					</ListItem>
	// 	    					</Collapse>);
	// 	    				menuViewList.push(menuItem);
	// 	    		}else if(Object.keys(list[key]).length == 0){

	// 	    			// root without child
	// 	    				const menuItem = (
	// 	    					<ListItem button onClick={this.props.handleRoute.bind(this, key)}>
	// 	          					<span className={classes.thiccer} style={{width: "12vw", fontWeight: "bold"}} >{CapitalizeFirstLetter(key)}</span>
	// 	          				</ListItem>);
	// 	    				menuViewList.push(menuItem);
	// 	    		}else if(parentName.parent_name != null){
	// 	    				let localkey = key;
	// 	    				// itermediate menu
	// 	    				const menuItem = (
	// 	    					<Collapse in={this.state[parentName.parent_name]} timeout="auto" unmountOnExit>
	// 		    					<ListItem style={{paddingLeft: (defaultpadding + menulvlSpacing * lvl) + "px"}} button onClick={() => {this.setState(state => ({[localkey]:  !this.state[localkey]})) }}>
	// 			          				<ListItemText primary = {CapitalizeFirstLetter(key)}/>
	// 			          					{this.state[localkey] ? <ExpandLess /> : <ExpandMore />}
	// 			          				</ListItem>
	// 	          				</Collapse>
	// 	          				);
	// 	    				menuViewList.push(menuItem);
	// 	    		}else{
	// 	    			// root with children
	// 	    			let localkey = key;
	// 	    				const menuItem = (
	// 	    					<ListItem button onClick={() => {this.setState(state => ({[localkey]:  !this.state[localkey]})) }}>
	// 	          				    <span className={classes.thiccer} style={{width: "12vw", fontWeight: "bold"}} >{CapitalizeFirstLetter(key)}</span>
	// 	          					   {this.state[localkey] ? <ExpandLess /> : <ExpandMore />}
	// 	          				</ListItem>
	// 	          				);
	// 	    				menuViewList.push(menuItem);
	// 	    		}


	// //	        }else{
	// 	        this.menuViewJSXCreate(list[key], menuViewList, lvl+1);
	// //	        }
	// 	    	}


	// 	    //console.log(menuViewList);
    // 		return menuViewList;
    // }

    render() {
        const { classes} = this.props;
        // let menuViewList=[];
        // console.log(this.state.menuBEData)
        // console.log(this.state.menuList)
        let menuData = this.state.menuBEData
        // let menulist = this.menuViewJSXCreate(this.state.menuList, menuViewList, 0);

        let nodes = null;

        if (this.state && this.state.menuBEData){
          let root = menuData.filter(node=>!node.hasOwnProperty("parent_id"))           

          nodes = root.map((node) => {
            // console.log(node.id)
            let children = menuData.filter(child=>
              // console.log("in child")
              // console.log(child);
              // console.log(child.parent_id)
              child.parent_id == node.id)
            // console.log(children)
            return (
              <MenuItem key={node.id} node={node} children={children} raw={menuData} handleRoute={this.props.handleRoute} parentCollapse={true}/>
            );
          })
        }
        
        // let nodes = 
        //   <div>
        //     <ul>
        //     <li> Module
        //     <ul>
        //       <li>
        //         Reg Compliance
        //         <ul>
        //           <li>tax</li>
        //           <li>labour</li>
        //         </ul>
        //       </li>
        //     </ul>
        //     </li>
        //     </ul>
        //   </div>;
        return (
            <div className={classes.rootMenu}>
                {/*<img src={logo} resizeMode={'contain'} style={styles.image}/>*/}
                <List
                    component =  "nav"
                >
		    {/* {menulist.map(item =>{
		        return(item);
                    })} */}
                {nodes}
                </List>                    
            </div>
        );
    }
}

SideMenuList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideMenuList);