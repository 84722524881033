import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AlertChip from '../../AlertChip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { Divider } from '@material-ui/core';

let counter = 0;

function createData(PO_ID, flags, CreatedBy, Created_Date, description, amount, approvedby, approvedDate) {
    counter += 1;
    return { id: counter, PO_ID, flags, CreatedBy, Created_Date, description, amount, approvedby, approvedDate};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'PO ID', numeric: true, disablePadding: false, label: 'PO ID' },
    { id: 'Description', numeric: true, disablePadding: false, label: 'Description' },
    { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },
    { id: 'Created By', numeric: true, disablePadding: false, label: 'Created By' },
    { id: 'Created Date', numeric: true, disablePadding: false, label: 'Created Date' },
    { id: 'Approved By', numeric: true, disablePadding: false, label: 'Approved By' },
    { id: 'Approved Date', numeric: true, disablePadding: false, label: 'Approved Date' },
    { id: 'flags', numeric: true, disablePadding: false, label: 'Flags' }
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, fields } = this.props;
        //console.log(fields);
        const defaultNumeric = true;
        const defaultPadding = false;
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {fields.map(row => {
                        return (
                            <TableCell
                                key={row}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row}
                                        direction={order}
                                        onClick={this.createSortHandler(row)}
                                    >
                                        {row}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;
    const searchBar = (<div style={{width: "60vw"}}>
        <Grid container spacing={8} alignItems="flex-end">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item xs={10}>
                <TextField id="input-with-icon-grid" label="Search..." fullWidth />
            </Grid>
        </Grid>
        <Divider />
    </div>);
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subheading">
                        {numSelected} selected
          </Typography>
                ) : (
                        searchBar
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        //minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class EntityTransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            // data:  [
            //     createData('OE11000660', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }, {
            //         name: "Duplicate Entry",
            //         avatar: "DE",
            //         color: "mid",
            //         avatarD: "1"
            //     }, {
            //         name: "Missing Amount",
            //         avatar: "MA",
            //         color: "high"
            //     }
            //     ], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000661', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }, {
            //         name: "Duplicate Entry",
            //         avatar: "DE",
            //         color: "mid"
            //     }], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000662', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }, {
            //         name: "Duplicate Entry",
            //         avatar: "DE",
            //         color: "mid"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000663', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }, {
            //         name: "Missing Amount",
            //         avatar: "MA",
            //         color: "high"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000660', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000664', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000665', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }, {
            //         name: "Duplicate Entry",
            //         avatar: "DE",
            //         color: "mid",
            //         avatarD: "1"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000666', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000667', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('WER Pte Ltd', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000668', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }], "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000669', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            //     createData('OE11000660', [{
            //         name: "Missing Documents",
            //         avatar: "MD",
            //         color: "mid"
            //     }],  "JiaJie", "14-SEP-18", "Hardware procurement from E SERVICES SINGAPORE PTE. LED.", 19730,"Yongyi","14-SEP-18"),
            // ],
            page: 0,
            rowsPerPage: 5,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    renderTransactions = () => {
        const { classes, type, transactions } = this.props;
        const { order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, transactions.items.length - page * rowsPerPage);
        //console.log(transactions);
        return (
            <div className={classes.root}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <div className={classes.tableWrapper}>
                    
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={transactions.items.length}
                                    fields={transactions.table_def}
                                />
                                <TableBody>
                                    {transactions.items
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            const isSelected = this.isSelected(n.id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={event => this.handleClick(event, n.id)}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    selected={isSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isSelected} />
                                                    </TableCell>
                                                    {transactions.table_def.map(f => {
                        //console.log(f)
                        //console.log(n[f])
                        if(f =="flags"){
                            return(<TableCell numeric style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>
                                {n[f]["High Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'high'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}
                                {n[f]["Medium Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'mid'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}
                                {n[f]["Low Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'low'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}

                                </TableCell>)
                        }else{
                            return (<TableCell numeric>{n[f]}</TableCell>);
                        }
                    }, this)}
                                                    {/* <TableCell numeric>
                                                        {n.PO_ID}
                                                    </TableCell>
                                                    <TableCell numeric>
                                                        {n.description}
                                                    </TableCell>
                                                    <TableCell numeric>
                                                        {n.amount}
                                                    </TableCell>
                                                    <TableCell numeric>{n.CreatedBy}</TableCell>
                                                    <TableCell numeric>{n.Created_Date}</TableCell>
                                                    <TableCell numeric>{n.approvedby}</TableCell>
                                                    <TableCell numeric>{n.approvedDate}</TableCell>
                                                    <TableCell numeric>{n.flags.map(e => {
                                                        i++;
                                                        return (<AlertChip
                                                            key={i}
                                                            label={e.name}
                                                            color={e.color}
                                                            avatar={e.avatarD}
                                                            style={{ margin: 2 }}
                                                        />)
                                                    })}</TableCell> */}

                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        
                </div>
                <TablePagination
                    component="div"
                    count={transactions.items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }

    render() {
        const {transactions} = this.props;
        //console.log(transactions);
        return transactions ? this.renderTransactions() : (
            <span>No Transactions</span>
        )
        
    }
}

EntityTransactionTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EntityTransactionTable);