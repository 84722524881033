import {createMuiTheme} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const paddingDefault = 15;
const paddingSubLevel = 12;
export const menuPadding = depth => {
    return {
        paddingLeft:(paddingDefault + paddingSubLevel * depth) + "px",
        paddingTop: '5px',
        paddingBottom: '5px',
    };
};

// use by all pages
export const theme = createMuiTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1100,
          xl: 1500
        }
      },
    typography: {
        useNextVariants: true,
        subtitle1:{
            color: grey[900],
        },
        //Table header
        subtitle2:{
            whiteSpace: 'nowrap',
            color: grey[900],
        },
        h6: {
            fontSize: "1.1rem",
            fontWeight: "400",
        },
        h5: {
            fontSize: '1rem',
            color: grey[900],        
        },
        body1: {
            color: grey[800],
        },
        //Table content
        body2: {
            fontSize: '0.7rem',
            color: grey[800],
        },
    },    
});