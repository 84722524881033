import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import EmailIcon from '@material-ui/icons/Email';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutlined';
import AttachFile from '@material-ui/icons/AttachFile';
import { Divider, Hidden } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input'
import TextField from '@material-ui/core/TextField';
import TaskList from "./TaskList";
import TaskLogList from "./TaskLogList";
import TaskDialog from "./TaskDialog";
//tabs related import
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
//dialog related import
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RegCompRoutineDetailTable from "./RegCompRoutineDetailTable";
import SearchableDropDown from '../SharedComponents/SearchableDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import moment from 'moment';

import config from "../../config/config";
import setting from "../../config/setting";
import axios from 'axios';
const styles = theme => ({
    padding: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    card: {
        // width: "100%",
        // height: '70vh',
        padding: 8,
    },
    tab:{
        display: 'flex',
        height: 56,
    },
    header: {
        textAlign: "left"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
        height: 56,
    },
    content: {
        textAlign: "left",
        // height: '55vh',
        overflow: "auto"
    },
    actionContent: {
        textAlign: "left",
        height: '50vh',
        overflow: "auto"
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    transaction: {
        paddingTop: 10,
        overflow: "auto",
        height: "10vh"
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

//demo data sets
const demo_tasks = {
    tasks:[
        {
            id: 1,
            description: "Age requirements",
            created_date: moment().add("days",-7).format(setting.date_format),
            due_date: moment().add("days",5).format(setting.date_format),
            status: "Completed",
            comment: "",
            created_user: 1,
            created_user_name: "adminA",
            assigned_user: 5,
            assigned_user_name: "staffB",
            alert:1,
            routine:"Labour & Social Security",
            questions: [
                {
                    id: 1,
                    question_type: "Boolean",
                    question_text: "Have age requirements for employees been met?",
                    response: "True",
                    attachments:[],
                },
                {
                    id: 2,
                    question_type: "Attachment",
                    question_text: "Evidence that age requirement has been met for all new hires",
                    response: "",
                    attachments:[{
                        file_name: "sample compliance form.xlsx",
                        size: "6167",
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    },],
                    attachment_id:18,
                },
            ],
        },
        {
            id: 2,
            description: "Foreign employees",
            created_date: moment().add("days",-7).format(setting.date_format),
            due_date: moment().add("days",5).format(setting.date_format),
            status: "Pending Response",
            comment: "",
            created_user: 1,
            created_user_name: "adminA",
            assigned_user: 5,
            assigned_user_name: "staffB",
            alert:1,
            routine:"Labour & Social Security",
            questions: [
                {
                    id: 1,
                    question_type: "Boolean",
                    question_text: "Have requirements for foreign employees been met?",
                    response: "",
                    attachments:[],
                },
                {
                    id: 2,
                    question_type: "Attachment",
                    question_text: "Academic qualification documents",
                    response: "",
                    attachments:[],
                },
                {
                    id: 3,
                    question_type: "Attachment",
                    question_text: "Competency certificate",
                    response: "",
                    attachments:[],
                },
                {
                    id: 4,
                    question_type: "Attachment",
                    question_text: "Statement Letter",
                    response: "",
                    attachments:[],
                },
                {
                    id: 5,
                    question_type: "Attachment",
                    question_text: "Taxpayer registration Number Letter",
                    response: "",
                    attachments:[],
                },
                {
                    id: 6,
                    question_type: "Attachment",
                    question_text: "Insurance Policy",
                    response: "",
                    attachments:[],
                },
                {
                    id: 7,
                    question_type: "Attachment",
                    question_text: "Evidence of membership with National Social Security",
                    response: "",
                    attachments:[],
                },
            ],
        },
    ],
    alert_id: "1",
    outstanding_task_count: 1,
};

const demo_logs = [
        {
            id: 1,
            action: "Create",
            action_user: "System",
            action_summary: "Task 'Age requirement' is created",
            time_stamp:moment().add("days",-7).format(setting.date_time_format),
        },
        {
            id: 2,
            action: "Create",
            action_user: "System",
            action_summary: "Task 'Foreign employee' is created",
            time_stamp:moment().add("days",-7).format(setting.date_time_format),
        },
        {
            id: 3,
            action: "Update",
            action_user: "System",
            action_summary: "Task 'Age requirement' is assigned to 'staffB'",
            time_stamp:moment().add("days",-7).format(setting.date_time_format),
        },
        {
            id: 4,
            action: "Update",
            action_user: "System",
            action_summary: "Task 'Foreign employee' is assigned to 'staffB'",
            time_stamp:moment().add("days",-7).format(setting.date_time_format),
        },
        {
            id: 5,
            action: "Update",
            action_user: "staffB",
            action_summary: "Task 'Age requirement' is completed and assigned to 'adminA'",
            time_stamp:moment().add("days",-5).format(setting.date_time_format),
        },
        // {
        //     id: 6,
        //     action: "Update",
        //     action_user: "adminA",
        //     action_summary: "Task 'Age requirement' is closed by 'adminA'",
        //     time_stamp:moment().add("days",-7).format(setting.date_format),
        // },
    ]

class RecipeReviewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryID:"",
            expanded: false,
            actionView: false,
            chips: [],
            open: false,
            assignmentOpen: false,
            taskDialogOpen: false,
            //defaultTab
            value: 0,
            reason: 0,
            closureComment: "",
            approvalComment: "",
            assignComment:"",
            assignedUserName:"",
            valueReason: 0,
            //alert information
            metaData: {
                routine: " PO",
                alert_ID: "",
                entity: "",
                owner_id: "",
                owner_name: "",
                status: "",
                created_date: "",
                outstanding_task_count: 0,
                name:""
            },
            //transaction related
            fields: [],
            data: [],
            // logs data
            logsfields: [
                "id",
                "action",
                "action summary",
                "action user",
                "time stamp"
            ],
            logsdata: demo_logs,
            tasksdata: demo_tasks,
            height:0,
            width: 0,
            checkTasksCompleted: false,
        };
        this.getAlertDetail = this.getAlertDetail.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.setState({ queryID:this.props.queryID });
        this.getAlertDetail();
        this.getActionLog();
        this.getTasks();
        this.getAssignee();
        this.setState({data:[]});
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentDidUpdate(prevProps){
        // console.log("new id : " + this.props.queryID + "vs old id : " + prevProps.queryID);
        if (this.props.queryID !== prevProps.queryID) {
            console.log("queryID changed from "+ prevProps.queryID + " to " +this.props.queryID)
            this.getAlertDetail();
            this.getActionLog();
            this.getTasks();
            this.getAssignee();
        }
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    getAssignee(){
        axios.get(config.assigneeList).then(res => {
            let listOfAssignee = res.data;
            // console.log("assignee");
            // console.log(this.props.username);
            // console.log(listOfAssignee);
            this.setState({
                listOfAssignee:listOfAssignee
            });
        })
    }
    getAlertDetail() {
        if (this.props.isDemo === true){
            this.setState({
                metaData: {
                    "routine": "",
                    "alert_ID": "",
                    "entity": "",
                    "owner_id": "",
                    "owner_name": "",
                    "status": "",
                    "created_date": "",
                    "name": ""
                    //"outstanding_task_count": res.data.outstanding_task_count,
                },
                fields: [],
                data: []
            });
        }else{
            axios.get(config.alert + this.props.queryID).then(res => {
                // console.log("below is the res from list detail")
                // console.log(res)
                this.setState({
                    metaData: {
                        "routine": res.data.routine,
                        "alert_ID": res.data.id,
                        "entity": res.data.entity,
                        "owner_id": res.data.owner,
                        "owner_name": res.data.owner_name,
                        "status": res.data.status,
                        "created_date": res.data.created_date,
                        "attachments": res.data.attachments,
                        "name": res.data.name
                        //"outstanding_task_count": res.data.outstanding_task_count,
                    },
                    fields: res.data.detail.table_def,
                    data: res.data.detail.items,
                    queryID:this.props.queryID
                });
                // console.log("attachments")
                // console.log(this.state.metaData.attachments)
            });
        }
    }

    getActionLog() {
        if (this.props.queryID == null){
            this.setState({
                logsdata: demo_logs
            });
        }else{
            console.log("axios request for logs");
            axios.get(config.alert + this.props.queryID + "/logs").then(res => {
                console.log("below is the res from action logs");
                console.log(res);
                 this.setState({
                     logsdata: res.data.logs
                 });
            });
        }
    }

    getTasks() {
        if (this.props.queryID == null){
            this.setState({
                tasksdata: demo_tasks,
            });
        }else{
            console.log("axios request for tasks");
            axios.get(config.alert + this.props.queryID + "/tasks").then(res => {
                console.log("below is the res from tasks");
                console.log(res);
                this.setState({
                    tasksdata: res.data,
                });
            });
        }
    }

    getTasksWithID = (ID) =>{

        // console.log("now get task with query ID :" + ID)
        if (ID == null){
            this.setState({
                tasksdata: []
            });
        }else{
            // console.log("axios request for logs");
            axios.get(config.alert + ID + "/tasks").then(res => {
                // console.log("below is the res from tasks");
                // console.log(res);
                this.setState({
                    tasksdata: res.data
                });
                this.forceUpdate();
            });
        }

        if (ID == null){
            this.setState({
                logsdata: demo_logs
            });
        }else{
            // console.log("axios request for logs");
            axios.get(config.alert + ID + "/logs").then(res => {
                // console.log("below is the res from action logs");
                // console.log(res);
                this.setState({
                    logsdata: res.data.logs
                });
            });
        }


        if (ID == null){
            this.setState({
                metaData: {
                    "routine": "",
                    "alert_ID": "",
                    "entity": "",
                    "owner_id": "",
                    "owner_name": "",
                    "status": "",
                    "created_date": "",
                    name: ""
                    //"outstanding_task_count": res.data.outstanding_task_count,
                },
                fields: [],
                data: []
            });
        }else{
            axios.get(config.alert + ID).then(res => {
                // console.log("below is the res from list detail")
                // console.log(res)
                this.setState({
                    metaData: {
                        "routine": res.data.routine,
                        "alert_ID": res.data.id,
                        "entity": res.data.entity,
                        "owner_id": res.data.owner,
                        "owner_name": res.data.owner_name,
                        "status": res.data.status,
                        "created_date": res.data.created_date,
                        "attachments": res.data.attachments,
                        name: res.data.name
                        //"outstanding_task_count": res.data.outstanding_task_count,
                    },
                    fields: res.data.detail.table_def,
                    data: res.data.detail.items,
                    queryID:this.props.queryID
                });
                // console.log("attachments")
                // console.log(this.state.metaData.attachments)
            });
        }
    };


    handleTaskSubmitUpdate = () => {
        console.log("get a task info update agian!!!!!!!");
        this.getTasks();
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };
    handleViewToggle = () => {
        this.setState(state => ({ actionView: this.state.actionView ? false : true }));
    };
    handleClickOpen = () => {
        this.setState({ open: true, checkTasksCompleted: false  });
        setTimeout(function() { //Start the timer
            this.setState({checkTasksCompleted: true})
        }.bind(this), 5000)
    };
    handleAssignmentClickOpen = () => {
        this.setState({ assignmentOpen: true});
    };
    handleTaskDialog = () => {
        this.setState({ taskDialogOpen: true });
        this.forceUpdate();
    };
    handleTaskDialogClose = () => {
        // console.log("set dialog state to false")
        this.setState({ taskDialogOpen: false });
        this.getTasks();
    };
    handleAlertActionCloseClose = () => {
        this.setState({ open: false });
        this.setState({ assignmentOpen: false });
    };

    handleAlertActionCloseSubmit = () => {

        let submitObj = {};
        submitObj.status = "Completed";
        submitObj.comment = this.state.closureComment;
        // submitObj.owner = this.state.metaData.owner_id;
        submitObj.reason = this.state.reason;
        //console.log("submit object:")
        //console.log(submitObj);
            axios.put(config.alert + this.props.queryID+"/close/", submitObj ).then(res=>{
                //console.log(res);
            }).catch(error => {console.log(error.response)})

            // axios.put(config.closeAlert, submitObj).then(res=>{
            //     //console.log(res);
            // }).catch(error => {console.log(error.response)})
            
            if ( this.state.attachment != null){
                let formData = new FormData();
                let uploads = this.state.attachment;
                //console.log(uploads)
                formData.append("file", uploads);
                //console.log(formData)
                axios.post(config.attachment, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                }).then(res=>{
                    //console.log(res);
                    var id = res.data.id
                    //console.log(id)
                    let fileObj = {};
                    fileObj.attached_content_type = "alert"
                    fileObj.attached_object_ID = this.props.queryID
                    //console.log(fileObj)
                
                    axios.put(config.attachment + "/" + id, fileObj).then(res=>{
                        //console.log(res);
                        this.getAlertDetail();
                    }).catch(error => {console.log(error.response)})
                }).catch(error => {console.log(error.response)})}

            this.setState({ open: false, setDataOutDated: true });

    };


    handleAlertCloseApprove = () => {
        let submitObj = {};
        submitObj.status = "Closed";
        submitObj.comment = this.state.approvalComment;
        submitObj.owner = this.state.metaData.owner_id;
        // console.log("submit object:")
        // console.log(submitObj);
        axios.put(config.alert + this.props.queryID+"/", submitObj ).then(res=>{
            // console.log(res);
        }).catch(error => {console.log(error.response)})

        var formData = new FormData();
        var uploads = this.state.attachment;
        //console.log(uploads)
        formData.append("file", uploads);
        //console.log(formData)

        axios.post(config.attachment, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(res=>{
            //console.log(res);
            var id = res.data.id
            //console.log(id)
            let fileObj = {};
            fileObj.attached_content_type = "alert"
            fileObj.attached_object_ID = this.props.queryID
            //console.log(fileObj)
    
            axios.put(config.attachment + "/" + id, fileObj).then(res=>{
                //console.log(res);
                this.getAlertDetail()
            }).catch(error => {console.log(error.response)})
        }).catch(error => {console.log(error.response)})
        

        this.setState(state => ({ actionView: this.state.actionView ? false : true }));

    };

    handleAlertAssignment = () => {
        let submitObj = {};
        // submitObj.status = this.state.metaData.status;
        submitObj.comment = this.state.assignComment;
        // submitObj.owner = this.state.metaData.owner_id;
        submitObj.assigned_user_name = this.state.assignedUserName;
        // console.log("submit assigment object:");
        // console.log(submitObj);
        if(submitObj.assigned_user_name != ""){
            axios.put(config.alert + this.props.queryID+"/close/", submitObj ).then(res=>{
                // console.log(res);
            }).catch(error => {console.log(error.response)})
            

            this.setState({ assignmentOpen: false });
        }else{
            alert("please choose assignee")
        }
    };

    handleAssigneeChange = (selectedOption) => {
        // this.setState({ selectedOption });
        // console.log(`Option selected:`, selectedOption);
        if (selectedOption)
        {        
            // let name = selectedOption.label;
            // console.log(name)
            let value = selectedOption.value;

            // console.log(value)
            this.setState({
                assignedUserName:value
            });
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleCloseDialogChange =  event => {
        let reason = event.target.value;
        this.setState({ reason: reason });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    handleFileUpload = (e) => {
        let value = e.target.files[0];
        // console.log(e.target.files[0])
        // console.log(e.target.files)
        // console.log(value)
        // console.log(this.props.queryID)
        this.setState(state =>{this.state.attachment=value;});
    };

    render() {
        //console.log(this.state.tasksdata)
        // console.log("in list detail render")
        // console.log(this.state.metaData)
        // console.log("the queryID of this listdetail is "+ this.props.queryID)
        // console.log("render with data ");
        // console.log(this.state.data);
        // console.log("data username : "+ this.state.metaData.owner_name+"    props username : "+ this.props.username)
        const { classes } = this.props;
        // console.log("pending tasks : "+ this.state.tasksdata)
        const testEmails = ["yongyibian@kpmg.com", "elisa@kpmg.com", "yuri@kpmg.com", "jiajie@kpmg.com", "koonhan@kpmg.com"]
        const inputHeader2 = (
            <ChipInput
                hintText='Assignees Email'
                dataSource={testEmails}
                fullWidth
            />
        )
        const inputHeader = (
            <TextField
                id="filled-full-width"
                label="To"
                style={{ margin: 8 }}
                placeholder="emails of the action recipient"

                fullWidth
                margin="normal"
                variant="filled"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        )
        const dialog = (<Dialog
            open={this.state.open}
            onClose={this.handleAlertActionCloseClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ overflow: Hidden, }}
        >
            <DialogTitle id="alert-dialog-title">{"Submit For Closure"}</DialogTitle>
            <DialogContent style={{ width: "55vh", height: "100vh" , overflow: Hidden }}>
                <DialogContentText>
                    Please select reason for closure
                </DialogContentText>
                <form>
                    <FormControl style={{ width: "90%" }}>
                        <Select
                            value={this.state.reason}
                            onChange={(event)=>{this.handleCloseDialogChange(event)}}
                            //input={<Input id="reason" />}
                            // fullWidth
                            style={{ width: "90%" }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Fulfill requirements</MenuItem>
                            <MenuItem value={2}>Approval Obtained</MenuItem>
                            <MenuItem value={3}>Should not be flagged</MenuItem>
                        </Select>
                    </FormControl>
                </form>
                <DialogContentText style={{ width: "90%" }}>
                    Comments
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    // fullWidth
                    style={{ width: "90%" }}
                    onChange = {(e)=>{this.setState({closureComment:e.target.value})}}
                />
                <DialogContentText>
                    Additional Attachment(optional)
                </DialogContentText>

                <input
                    hidden
                    id="raised-button-file" 
                    multiple 
                    type="file"
                    onChange = {this.handleFileUpload}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" size="small" component="span">
                        <AttachFile />
                        Attach File
                    </Button>
                </label>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleAlertActionCloseClose} color="secondary">
                    Cancel
              </Button>
                <Button onClick={this.handleAlertActionCloseSubmit} color="primary" autoFocus>
                    Submit
              </Button>
            </DialogActions>
        </Dialog>
        )

        const options = this.state.listOfAssignee ? this.state.listOfAssignee.map(user=>({
            value: user.username,
            label: user.username
        })) : undefined;

        const assignmentDialog = (<Dialog
            open={this.state.assignmentOpen}
            onClose={this.handleAlertActionCloseClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ overflow: Hidden }}
        >
            <DialogTitle id="alert-dialog-title">{"Assign Ownership To..."}</DialogTitle>
            <DialogContent style={{ width: "55vh", minHeight: "50vh", overflow: Hidden }}>
                {/* <TextField
                    autoFocus
                    margin="dense"
                    type = "email"
                    id="name"
                    fullWidth
                    onChange = {(e)=>{this.setState({assignedUserName:e.target.value})}}
                /> */}
                <SearchableDropDown 
                    options= {options}
                    changeHandler={this.handleAssigneeChange}
                    placeholder={"Select a user"}
                />
                <DialogContentText>
                    Comments
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    onChange = {(e)=>{this.setState({assignComment:e.target.value})}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleAlertActionCloseClose} color="secondary">
                    Cancel
              </Button>
                <Button onClick={this.handleAlertAssignment} color="primary" autoFocus>
                    Submit
              </Button>
            </DialogActions>
        </Dialog>
        )

        const actionView = (
            <Card className={classes.card}>

{/*                <CardHeader
                    //avatar={
                    //  <Button variant="outlined" size="medium" color="primary">
                    //      <EmailIcon />
                    //  </Button>
                    }
                    title={inputHeader}
                    className={classes.header}
                />
                <Divider />*/}

                <CardContent className={classes.actionContent}>
                    <span style={{ color: "rgb(117,117,117)" }}>Remarks</span>
                    <textarea onChange = {(e)=>{this.setState({approvalComment:e.target.value})}} style={{ width: "42vw", height: "40vh", fontSize: "1.1em" }} placeholder="Please input reasons or instructions before you dispatch this alert to another person in charge"></textarea>
                    <span style={{ width: "42vw", display: "flex", justifyContent: "space-between" }}>
                        <input
                        hidden
                        id="raised-button-file-approve" 
                        multiple 
                        type="file"
                        onChange = {this.handleFileUpload}
                        />
                        <label htmlFor="raised-button-file-approve">
                            <Button variant="contained" size="small" component="span">
                                <AttachFile />
                                Attach File
                            </Button>
                        </label>
                        <span style={{ width: "18vw", display: "flex", justifyContent: "space-between" }}>
                            <Button color="secondary" variant="contained" size="small" onClick={this.handleViewToggle} style={{ width: "5vw" }}>
                                <DeleteOutline />
                                Discard
                            </Button>
                            <Button variant="contained" size="small" onClick={() => {this.handleAlertCloseApprove()}} style={{ width: "5vw" }}>
                                <EmailIcon />
                                Send
                            </Button>
                        </span>
                    </span>
                </CardContent>
            </Card>
        );
        const content_height = (this.state.height - 252) + "px";
        const content_style = {
            height: content_height,
        };
        const detailsView = (
            <Card className={classes.card}>
                {this.state.taskDialogOpen ? <TaskDialog id={this.props.selectedAlert.compliance_id} open={this.state.taskDialogOpen} close = {this.handleTaskDialogClose} users={this.state.listOfAssignee}/> : null}
                <CardActions className={classes.actions}>
                    {["Closed","Pending Approval For Closure"].indexOf(this.state.metaData.status) < 0  ? <Tooltip title="Submit for closure"><Button style={{ width: "8vw" }} variant="outlined" onClick={this.handleClickOpen}>Close</Button></Tooltip>: null}                    
                    {this.state.metaData.status == "Pending Approval For Closure" ? <Button style={{ width: "8vw" }} variant="outlined" onClick={this.handleViewToggle}>Approve</Button>: null}                    
                    <Tooltip title="Assign ownership to"><Button style={{ width: "8vw" }} variant="outlined" onClick={this.handleAssignmentClickOpen}>Assign</Button></Tooltip>
                    <Button style={{ width: "12vw" }} variant="outlined" onClick={this.handleTaskDialog}>New Task</Button>
                    {/* <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                    >
                        <ChevronRight />
                    </IconButton> */}
                </CardActions>
                <Divider />
                <CardContent className={classes.content} style={content_style}>
                    {/* <RoutineDescriptionTable {...this.state} /> */}
                    <RegCompRoutineDetailTable selectedAlert={this.props.selectedAlert} />
                    {/* <DuplicateTxnTable
                        count={this.props.count == null ? 0 : (this.props.count + 1)}
                        username={this.props.username}
                        data={this.state.data}
                        header={this.state.fields}
                        taskRefresh={this.getTasksWithID}
                        metadata = {this.state.metaData}
                    />
                    <AttachmentList attachments={this.state.metaData.attachments}/> */}
                </CardContent>

                {dialog}
                {assignmentDialog}
            </Card>
        );
        const routineTabs = (
            <div>
                {this.props.selectedAlert ? <Typography variant='subtitle1'>{this.props.selectedAlert.compliance_requirement}</Typography> : null}
                <Tabs
                    className={classes.tab}
                    value={this.state.value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                >
                    <Tab label="Details" />
                    <Tab label={
                        <Badge color="secondary" className={classes.padding} badgeContent={this.state.tasksdata.outstanding_task_count} >
                            Task Status
                      </Badge>
                    } />
                    <Tab label="Action Logs" />
                </Tabs>
                <Divider />
                {this.state.value == 0 ? (this.state.actionView ? actionView : detailsView) : null}
                {this.state.value === 1 ? <TaskList submitUpdate = {this.handleTaskSubmitUpdate} owner={this.state.metaData.owner_name === this.props.username} data={this.state.tasksdata} users={this.state.listOfAssignee}/> : null}
                {this.state.value === 2 ? <TaskLogList data={this.state.logsdata} header={this.state.logsfields}/> : null}
                <Divider />
            </div>
        )
        return (
            <div>
                {routineTabs}
            </div>
        );
    }
}

RecipeReviewCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);