import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import config from "../../config/config";
import axios from 'axios';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	},
	error: {
		color: '#cc0033',
		display: 'inline-block',
		fontSize: '15px',
		lineHeight: '15px',
		margin: '5px 0 0',
	},
});

class SignIn extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			errormsg : ""
		}
		// console.log("init")
	}

	componentDidMount(){
		sessionStorage.removeItem("loginState");
		sessionStorage.removeItem("username");
		sessionStorage.removeItem("token");
	}

	LoginVerification = (event) => {
		// prevent default form submit actions
		event.preventDefault();
		let usernameValue = event.target.username.value;
		let passwordValue = event.target.password.value;
		let rememberme = event.target.remember.checked;
		console.log(this.props.location);
		axios.post(config.auth + "/login/", {},
			{
				auth: {
					username: usernameValue,
					password:passwordValue
				}
			}
			).then(res => {
			this.props.login(true, usernameValue, res.data);
			// console.log(this.props.location.pathname);
			this.props.history.push((this.props.location.pathname == "/" | this.props.location.pathname == "/login") ? "/my dashboard" : {path : this.props.location.pathname, search: this.props.location.search});
		}).catch(error => {
			let msg = error.message;
			if (msg.includes('401')){
				msg = "Username and password does not match";
			}
			this.setState({
				errormsg : msg
			});
			//console.log(error.message);
			//console.log(error);
			//console.log("error");
		});
	};
	render(){
		// console.log("in render")
		// console.log(this.state.errormsg)
		const { classes } = this.props;
		return (
			<main className={classes.main}>
				<CssBaseline />
				<Paper className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<form className={classes.form} onSubmit = {this.LoginVerification.bind(this)}>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="email">User Name</InputLabel>
							<Input id="username" name="username" autoComplete="username" autoFocus />
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="password">Password</InputLabel>
							<Input name="password" type="password" id="password" autoComplete="current-password" />
							{this.state.errormsg == "" ? null : <span className = {classes.error}>{this.state.errormsg}</span>}
						</FormControl>					
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" id="remember"/>}
							label="Remember me"
						/>			
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
						>
						Sign in
						</Button>	
					</form>
				</Paper>
			</main>
		);
	}
}
SignIn.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn)