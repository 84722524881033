import React from 'react';
import PropTypes from 'prop-types';
import {   withStyles,
  MuiThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import VendorAlertTable from "./VendorAlertTable";
import CustomerAlertTable from "./CustomerAlertTable";
import EmployeeAlertTable from "./Entities/Employees/EmployeeAlertTable";
import SideMenuList from "./SharedComponents/SideMenuList_try";
import TransactionAlerts from "./Transactions/TransactionAlerts";
import RoutineDetailTable from "./Routines/RoutineDetailTable";
import Tooltip from '@material-ui/core/Tooltip';
// import Dashboard from "./Dashboard/DashboardNew";
import Dashboard from "./Dashboard/MyDashboard";
import RegionalDashboard from "./Dashboard/RegionalDashboard";
import CountryDashboard from "./Dashboard/CountryDashboard";
import EntityCustomerTable from "./Entities/Employees/EntityCustomerTable";
import {CapitalizeFirstLetter} from '../util/StringUtil';

import Http404 from "./Error/http404";

//import for variat drawer
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import logo from '../pic/KPMG_logo.svg.png'

import axios from 'axios';
import config from '../config/config';

import {theme} from '../components/CSS/CommonStyles';

const drawerWidth = 240;

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 10,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 10,
    },
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    marginTop: '58px',
    padding: '8px',
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  rootMenu: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested0: {
    paddingLeft: theme.spacing.unit * 4,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2,
  },
  avatar: {
    width: 30,
    height: 30
  },
  thicc: {
    fontWeight: 600,
  },
  thiccer: {
    fontWeight: 600,
  }
});

class ClippedDrawer extends React.Component {
  state = {
    perspective: "Dashboard",
    displayName: "Dashboard",
    open: true,
    menuOpen: false,
    procurementOpen: false,
    ERMOpen: false,
    routinesOpen: false,
    transactionsOpen: false
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleClick = () => {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  };
  handleRoute = (route, name) => {
    this.setState({ perspective: route, displayName: name ? name : route });
    // console.log(this.state.displayName);
    // console.log(this.state.perspective);
    // console.log("clicked menu");
    // switch ( this.props.location.pathname.substr(1)) {
    //   case 'dashboard':
    //     this.setState({ perspective: 'Dashboard'});
    //     break
    //   case 'transactions':
    //     this.setState({ perspective: 'Transactions'});
    //     break
    //   case 'vendors':
    //     this.setState({ perspective: 'Vendors', menuOpen:true });
    //     break
    //   case 'employees':
    //     this.setState({ perspective: 'Employees' });
    //     break
    //   case 'purchaseorders':
    //     this.setState({ perspective: 'Purchase Orders', transactionsOpen:true });
    //     break
    //   case 'invoices':
    //     this.setState({ perspective: 'Invoices', transactionsOpen:true });
    //     break
    //   case 'customers':
    //     this.setState({ perspective: 'Customers', menuOpen:true });
    //     break
    //   case 'conflictinterest':
    //     this.setState({ perspective: 'Conflict Interest', routinesOpen:true, ERMOpen:true});
    //     break
    //   case 'duplicateaddress':
    //     this.setState({ perspective: 'Duplicate Address', routinesOpen:true, ERMOpen:true});
    //     break
    //   case 'duplicatepo':
    //     this.setState({ perspective: 'Duplicate PO', routinesOpen:true, procurementOpen:true});
    //     break
    //   case 'benford':
    //     this.setState({ perspective: 'Benford', routinesOpen:true, ERMOpen:true});
    //     break
    //   default:
    //     this.setState({ perspective: 'Dashboard'});
    // }
    this.props.history.push('/'+route.toLowerCase());
  }

  handleLogOut = ()=>{
      
      axios.post(config.auth + '/logout/', {},
      {}).then(res => {console.log(res)})
      .catch(error => {
          console.error(error)
      })
      axios.defaults.headers.common["Authorization"]='';
      this.props.logout();
      this.props.history.push("/login");
  }

  componentWillMount(){
    axios.defaults.headers.common["Authorization"] = 'token ' + this.props.token;
    // set perspective based on route
    // let route = this.props.location.pathname.substr(1)
    // if (this.state.perspective != route)
    // {
    //   this.setState({
    //     perspective: route
    //   })
    //   this.props.history.push('/'+route.toLowerCase().replace(/\s/g,''));
    // }
    // console.log(this.props.location.pathname.substr(1))
    // console.log( this.state.displayName+"will mount")
    // console.log( this.props)
  }

  render() {
    const { classes } = this.props;
    let toRender = null;
    const vendorAlertTable = (<VendorAlertTable />);
    const customerAlertTable = (<CustomerAlertTable />);
    const employeeAlertTable = (<EmployeeAlertTable />);
    const routineDetailTable = (<RoutineDetailTable />);
    const transactionAlerts = (<TransactionAlerts type={"purchase orders"}/>);
    const dashboard = (<Dashboard />);
    const entityCustomerTable = (<EntityCustomerTable />);
    const regionalDashboard = (<RegionalDashboard />);
    const countryDashboard = (<CountryDashboard />);
    const http404 = (<Http404 />);
    //console.log(this.props.location);
    let title = this.props.location.pathname.substr(1);
    let display = title;
    const search = this.props.location.search;
    switch (title) {
      case 'dashboard':
      case 'my dashboard':
        toRender = dashboard;
        break
      case 'regional dashboard':
        toRender = regionalDashboard;
        break        
      case 'country dashboard':
        if (search) {
          const params = new URLSearchParams(search);
          const country = params.get('country');
          //console.log(country);
          toRender = (<CountryDashboard country={country}/>);
        } else {
          toRender = countryDashboard;
        }
        break
      case 'transaction':
        toRender = transactionAlerts;
        break
      case 'vendor':
        toRender = employeeAlertTable;
        break
      case 'employee':
        toRender = employeeAlertTable;
        break
      case 'purchase order':
      case 'purchase orders':
        toRender = transactionAlerts;
        display = 'purchase order'
        break
      case 'invoice':
      case 'invoices':
      case 'goods receipts':
      case 'payments':
        toRender = <TransactionAlerts type={title}/>;
        break
      case 'customer':
        toRender = entityCustomerTable;
        break
      case 'conflict of interest':
      // console.log("now at conflict interest");
        toRender = <RoutineDetailTable username={this.props.username} type={"conflict of interest"}/>;
        display = 'conflict of interest';
        break
      case 'duplicate address':
      case 'open invoice':
      case 'vendor same gst':
      case 'vendor same address':
        toRender = <RoutineDetailTable username={this.props.username} type={title}/>;
        // display = 'duplicate address';
        break
      case 'vendor same gst':
        toRender = <RoutineDetailTable username={this.props.username} type={title}/>;
        display = 'vendor same GST';
      case 'open po':
        toRender = <RoutineDetailTable username={this.props.username} type={"open po"}/>;
        display = 'open PO';
        break  
      case 'duplicate po':
        toRender = <RoutineDetailTable username={this.props.username} type={"duplicate po"}/>;
        display = 'duplicate PO';
        break
      case 'benford':
        toRender = customerAlertTable;
        break
      case 'enterprise rm':
        toRender = dashboard;
        display = 'enterprise RM';
        break
      case 'singapore':
      case 'vietnam':
      case 'malaysia':
      case 'indonesia':
        if (search) {
          const params = new URLSearchParams(search);
          const searchVal = params.get('searchVal');
          //console.log(searchVal);
          toRender = <RoutineDetailTable username={this.props.username} type={title} country={title} searchVal={searchVal}/>;
        } else {
          toRender = <RoutineDetailTable username={this.props.username} type={title} country={title}/>;
        }        
        break
      case 'http404':
        toRender = http404;
        display = "Not Found";
        break
      default:
        toRender = <RoutineDetailTable username={this.props.username} type={title}/>;

    }
    


    return (
      <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="absolute" className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar style={{backgroundColor:"rgb(0,145,218)", minHeight: "58px"}}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              {CapitalizeFirstLetter(display)}
            </Typography>
            <div style={{flex:'1', textAlign:'right'}}>
              <Tooltip title="Log Out" aria-label="Log Out">
                  <IconButton style={{color:'rgb(255,255,255)'}}                           
                      onClick={()=>{
                      // console.log('log out'); 
                      this.handleLogOut();
                      }}>
                      <i class="fas fa-sign-out-alt fa-1x" ></i>
                  </IconButton>
              </Tooltip>  
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
{/*          <div className={classes.toolbar}>
            { <IconButton onClick={this.handleDrawerClose}>
              {!this.state.open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton> }
          </div>*/}
          <img src={logo} resizeMode={'contain'} style={{width:"30%", height:"auto", marginLeft:"auto", marginRight:"auto", marginTop:"8%"}}/>
          <SideMenuList handleRoute={this.handleRoute}/>
        </Drawer>
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          {toRender}
        </main>
      </div>
      </MuiThemeProvider>
    );
  }
}

ClippedDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClippedDrawer);
