import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
//import ListDetail from "../../ListDetail/ListDetail";
import VendorListDetail from '../Employees/VendorListDetail';
//searchBar related import
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import AlertChip from "../../AlertChip";

import config from "../../../config/config";
import axios from 'axios';

let counter = 0;
function createData(name, detailedFlags, actions, carbs, protein, flags) {
    counter += 1;
    return { id: counter, detailedFlags, name, flags, actions, carbs, protein, flags };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Vendor Name' },
    { id: 'flags', numeric: true, disablePadding: false, label: 'Flags' }
];


class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        {/* <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        /> */}
                    </TableCell>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        height: 'flex'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subheading">
                        {numSelected} selected
          </Typography>
                ) : (
                        <Typography variant="title" id="tableTitle">
                            Vendors
          </Typography>
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Button variant="outlined" size="small" onClick={this.handleViewToggle}>Action..</Button>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
        height: '75vh'
    },
    contentWrapper: {
        height: '100%',
    },
    margin: {
        height: 56,
        marginLeft: theme.spacing.unit,
        padding: 8,
    }
});

class VendorAlertTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            data: [
            ],
            fields: [],
            page: 0,
            rowsPerPage: 10,
            details: true,
            filtered: [],
        };
        this.getVendors = this.getVendors.bind(this);
    }


    componentWillMount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        console.log("employmrny mounted")
        console.log(this.props.taskRefresh)
        this.getVendors();
        //console.log("vendor data load finish");
    }
    getVendors = () => {
        //console.log("test axios getVendors");
        axios.get(config.entities("vendor"))
            .then(res => {
                let vendors = res.data.items;
                let fields = res.data.table_def;
                this.setState({ data: vendors, fields: fields, filtered: vendors, detailSelected:vendors[0].id});
                //console.log(this.state.data);
            })
    }
    // hard-coded sample data
    // state = {
    //     open: true,
    //     anchor: 'right',
    //     order: 'asc',
    //     orderBy: 'name',
    //     details: true,
    //     list: 3,
    //     selected: [],
    //     data: [
    //         createData('Openlane', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }, {
    //             name: "Duplicate Address",
    //             avatar: "DA",
    //             color: "primary",
    //         }, {
    //             name: "Duplicate PO",
    //             avatar: "DP",
    //             color: "secondary"
    //         }
    //         ], 3.7, 67, 4.3, {
    //                 high: 5,
    //                 mid: 2,
    //                 low: 3
    //             }), 
    //         createData('Yearin', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }, {
    //             name: "Duplicate Address",
    //             avatar: "DA",
    //             color: "primary"
    //         }], 25.0, 51, 4.9, {
    //                 high: 4,
    //                 mid: 2,
    //             }),
    //         createData('Acme Corporation', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }, {
    //             name: "Duplicate Address",
    //             avatar: "DA",
    //             color: "primary"
    //         }], 16.0, 24, 6.0, {
    //                 mid: 2,
    //             }),
    //         createData('Globex Corporation', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }, {
    //             name: "Duplicate PO",
    //             avatar: "DP",
    //             color: "secondary"
    //         }], 6.0, 24, 4.0, {
    //                 high: 1,
    //                 mid: 5,
    //                 low: 3
    //             }),
    //         createData('Soylent Corp', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 16.0, 49, 3.9, {
    //                 high: 1
    //             }),
    //         createData('Hooli', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 3.2, 87, 6.5, {
    //                 low: 3
    //             }),
    //         createData('Initech ', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 9.0, 37, 4.3, {
    //                 high: 6,
    //                 mid: 5,
    //                 low: 3
    //             }),
    //         createData('Vehement Capital Partners', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 0.0, 94, 0.0, {
    //                 high: 1,
    //                 low: 3
    //             }),
    //         createData('Umbrella Corporation', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 26.0, 65, 7.0, {
    //                 high: 1,
    //                 mid: 2,
    //             }),
    //         createData('Massive Dynamic', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 0.2, 98, 0.0, {
    //                 high: 1,
    //                 low: 9
    //             }),
    //         createData('Zotware', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 0, 81, 2.0, {
    //                 low: 3
    //             }),
    //         createData('Plusstrip', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 19.0, 9, 37.0, {
    //                 low: 3
    //             }),
    //         createData('Scottech', [{
    //             name: "Confict Interest",
    //             avatar: "CI",
    //             color: "primary"
    //         }], 18.0, 63, 4.0, {
    //                 low: 3
    //             }),
    //     ],
    //     page: 0,
    //     rowsPerPage: 10,
    // };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleAction = (event, id) => {
        // this.setState({ details: this.state.details ? false : true });
        // this.setState({ list: this.state.list == 3 ? 12 : 3 });
        //console.log("clicked");
        //console.log(id);
        this.setState({detailSelected: id});
    }
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    renderChips = (flags) => {
        let toReturn = [];
        if (flags.high) {
            toReturn.push(<Tooltip title="09/09/2018" placement="top">
                <AlertChip
                    TLight="true"
                    color="high"
                    avatar={flags.high}
                />
            </Tooltip>);
        }
        if (flags.mid) {
            toReturn.push(<Tooltip title="09/09/2018" placement="top">
                <AlertChip
                    TLight="true"
                    color="mid"
                    avatar={flags.mid}
                />
            </Tooltip>)
        }
        if (flags.low) {
            toReturn.push(<Tooltip title="09/09/2018" placement="top">
                <AlertChip
                    TLight="true"
                    color="low"
                    avatar={flags.low}
                />
            </Tooltip>)
        }
        return <div>{toReturn}</div>;
    }

    _onChangeSearch(e){
        //console.log(e.target.value);
        if (e.target.value){
            var filtered = this.state.data.filter(function(itm){
                return itm.name.toLowerCase().includes(e.target.value.toLowerCase())
            });
        } else {
            var filtered = this.state.data
        }
        
        //console.log(filtered);
        this.setState({filtered: filtered})
    }

    render() {
        const { classes, theme } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, anchor, open, details, list, filtered,detailSelected } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        console.log("vendor filtered")
        console.log(this.state.filtered);
        const detailsView = (<Grid item md={9} className={classes.contentWrapper}>
            <VendorListDetail detailID={detailSelected}/>
        </Grid>);
        const searchBar = (<div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item>
                    <TextField id="input-with-icon-grid" label="Search..." fullWidth={true} onChange={this._onChangeSearch.bind(this)} value={this.state.inputValue}/>
                </Grid>
            </Grid>
            <Divider />
        </div>);

        return (
            <Paper className={classes.root} style={{ height: "92vh" }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <Grid container padding>
                    <Grid item md={list}>
                        <Card className={classes.tableWrapper}>
                            {searchBar}
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {filtered
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            const isSelected = this.isSelected(n.id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    selected={isSelected}
                                                    padding
                                                 >
                                                    <TableCell padding="checkbox"
                                                        /*onClick={event => this.handleClick(event, n.id)}*/>
                                                        {/* <Checkbox checked={isSelected} /> */}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none" onClick={event => this.handleAction(event, n.id)} key={n.id}>
                                                        {n.name}
                                                    </TableCell>
                                                    {/* <TableCell numeric>{this.renderChips(n.flags)}</TableCell> */}
                                                    {
                                                        this.state.fields.map(f => {
                                                            if(f =="flags"){
                                                                return(<TableCell numeric style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>
                                                                    {n.flags["High Risk"].map(e => {
                                                                        i++;
                                                                        return (<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'high'}
                                                                            username={this.props.username}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            metadata = {this.props.metadata}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                        />)
                                                                    })}
                                                                    {n.flags["Medium Risk"].map(e => {
                                                                        i++;
                                                                        return (<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'mid'}
                                                                            username={this.props.username}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            metadata = {this.props.metadata}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                        />)
                                                                    })}
                                                                    {n.flags["Low Risk"].map(e => {
                                                                        i++;
                                                                        return (<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'low'}
                                                                            username={this.props.username}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            metadata = {this.props.metadata}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                        />);
                                                                    })}




{/*                                                                    {n.flags["High Risk"].length >0 ? <AlertChip
                                                                            label={n.flags["High Risk"].length}
                                                                            color={'high'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            /> : ''}
                                                                    {n.flags["Medium Risk"].length >0 ? 
                                                                    <AlertChip
                                                                            label={n.flags["Medium Risk"].length}
                                                                            color={'mid'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            /> : ''}
                                                                    {n.flags["Low Risk"].length >0 ?
                                                                    <AlertChip
                                                                            label={n.flags["Low Risk"].length}
                                                                            color={'low'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            /> : ''}*/}

                                                                    </TableCell>)
                                                            }
                                                        })
                                                    }

                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                    {this.state.details ? detailsView : null}
                </Grid>
                <Divider />
                <TablePagination 
                    component="div"
                    count={filtered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

VendorAlertTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorAlertTable);