const KPMGCOLOR = {
    BLUE: 'rgb(0, 51, 141)',
    MEDIUM_BLUE: 'rgb(0, 94, 184)',
    LIGHT_BLUE: 'rgb(0, 145, 218)',
    VIOLET: 'rgb(72, 54, 152)',
    PURPLE: 'rgb(71, 10, 104)',
    LIGHT_PURPLE: 'rgb(109, 32, 119)',
    GREEN: 'rgb(0, 163, 161)',
    DARK_GREEN:'rgb(0, 154, 68)',
    LIGHT_GREEN:'rgb(67, 176, 42)',
    YELLOW:'rgb(234, 170, 0)',
    ORANGE:'rgb(246, 141, 46)',
    RED:'rgb(188, 32, 75)',
    PINK:'rgb(199, 0, 126)',
    DARK_BROWN:'rgb(117, 63, 25)',
    LIGHT_BROWN:'rgb(155, 100, 46)',
    OLIVE:'rgb(157, 147, 117)',
    BELGE:'rgb(227, 188, 159)',
    LIGHT_PINK:'rgb(227, 104, 119)',    
}

const setting = {
    date_format : "YYYY-MM-DD",
    date_time_format : "YYYY-MM-DD HH:mm:ss",
    COLOR: {
        RED: KPMGCOLOR.RED,
        GREEN: KPMGCOLOR.LIGHT_GREEN,
        YELLOW: KPMGCOLOR.YELLOW,
        MEDIUM_BLUE: KPMGCOLOR.MEDIUM_BLUE,
        OLIVE: KPMGCOLOR.OLIVE,
    },
};

export default setting;
