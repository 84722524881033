import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AlertChip from '../AlertChip';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import config from "../../config/config";
import axios from 'axios';


let counter = 0;
function createData(PO_ID, flags, CreatedBy, Created_Date, Vendor_Name, amount) {
    counter += 1;
    return { id: counter, PO_ID, flags, CreatedBy, Created_Date, Vendor_Name, amount};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class EnhancedTableHead extends React.Component {
    constructor(props) {
        super(props);
        this.getTableHeader = this.getTableHeader.bind(this);
        this.state = {
            rows: [],
        }
    }
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    componentWillMount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this.getTableHeader();
    }
    getTableHeader = () => {
        // console.log("log out invoice data now");
        axios.get(config.transaction, {
            params: { 'type': 'invoices' }
        }).then(res => {
            // console.log(res);
            let headers = res.data.results.table_def;
            // console.log(headers);
            this.setState({ rows: headers });
        });
    }


    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {this.state.rows.map(row => {
                        return (
                            <TableCell>
                                {row}
                            </TableCell>
                            //<TableCell
                            //    key={row.id}
                            //    numeric={row.numeric}
                            //    padding={row.disablePadding ? 'none' : 'default'}
                            //    sortDirection={orderBy === row.id ? order : false}
                            //>
                            //    <Tooltip
                            //        title="Sort"
                            //        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            //        enterDelay={300}
                            //    >
                            //        <TableSortLabel
                            //            active={orderBy === row.id}
                            //            direction={order}
                            //            onClick={this.createSortHandler(row.id)}
                            //        >
                            //            {row.label}
                            //        </TableSortLabel>
                            //    </Tooltip>
                            //</TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;
    const searchBar = (<div style={{ width: "60vw" }}>
        <Grid container spacing={8} alignItems="flex-end">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item xs={10}>
                <TextField id="input-with-icon-grid" label="Search..." fullWidth />
            </Grid>
        </Grid>
        <Divider />
    </div>);
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subheading">
                        {numSelected} selected
          </Typography>
                ) : (
                        searchBar
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        //minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class TransactionTable extends React.Component {
//    state = {
//        order: 'asc',
//        orderBy: 'name',
//        selected: [],
//        data: [
//            createData('OE11000660', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }, {
//                name: "Duplicate Entry",
//                avatar: "DE",
//                color: "mid",
//                avatarD: "1"
//            }, {
//                name: "Missing Amount",
//                avatar: "MA",
//                color: "high"
//            }
//            ], "JiaJie", "14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000661', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }, {
//                name: "Duplicate Entry",
//                avatar: "DE",
//                color: "mid"
//            }], "JiaJie", "14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000662', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }, {
//                name: "Duplicate Entry",
//                avatar: "DE",
//                color: "mid"
//            }], "JiaJie", "14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000663', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }, {
//                name: "Missing Amount",
//                avatar: "MA",
//                color: "high"
//            }], "JiaJie", "24-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000660', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "JiaJie", "12-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000664', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "JiaJie", "19-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000665', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }, {
//                name: "Duplicate Entry",
//                avatar: "DE",
//                color: "mid",
//                avatarD: "1"
//            }], "Eric", "14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000666', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric","14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000667', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric", "14-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('WER Pte Ltd', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric","24-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000668', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric", "04-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000669', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric", "15-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//            createData('OE11000660', [{
//                name: "Missing Documents",
//                avatar: "MD",
//                color: "mid"
//            }], "Eric", "16-SEP-18", "procurement from E SERVICES SINGAPORE PTE. LED.", 19400),
//        ],
//        page: 0,
//        rowsPerPage: 10,
//    };

    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            data: [
            ],
            fields: [],
            page: 0,
            rowsPerPage: 10,
        };
        this.getTransactions = this.getTransactions.bind(this);
    }


    componentWillMount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this.getTransactions();
    }
    getTransactions = () => {
        // console.log("test axios");
        axios.get(config.transaction,{
            params: { 'type': 'invoices' }
        })
            .then(res => {
                let invoice = res.data.results.items;
                let fields = res.data.results.table_def;
                this.setState({ data: invoice, fields: fields });
                // console.log(this.state.data);
            })
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <div className={classes.root}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <div className={classes.tableWrapper}>
                    <Grid container>
                        <Grid item md={12}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {this.state.data
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            const isSelected = this.isSelected(n.id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={event => this.handleClick(event, n.id)}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    selected={isSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isSelected} />
                                                    </TableCell>
                                                   {
                                                        this.state.fields.map(f => {
                                                            if(f =="flags"){
                                                                return(<TableCell style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>
                                                                    {n.flags["High Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'high'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}
                                                                    {n.flags["Medium Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'mid'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}
                                                                    {n.flags["Low Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e}
                                                                            color={'low'}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}

                                                                    </TableCell>)
                                                            }else{
                                                                return (<TableCell>{n[f]}</TableCell>);
                                                            }
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        {/* <Grid item md={6}>
                            <div style={{ width: "100%", height: "45vh" }}>
                                <Toolbar>Transaction Details</Toolbar>
                                <div style={{ height: "38vh", overflow: "auto" }} >
                                    <TransactionDetailTable />
                                </div>
                            </div>
                        </Grid> */}

                    </Grid>
                </div>
                <TablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

TransactionTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransactionTable);