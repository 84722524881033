import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chart from 'chart.js';

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {HorizontalBar} from 'react-chartjs-2';

import blue from '@material-ui/core/colors/blue';

import config from "../../config/config";
import axios from 'axios';
import setting from "../../config/setting";

//maps
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker
} from "react-simple-maps";
import countriesMap from '../../pic/TM_WORLD_BORDERS-0.3.json'
import continentMap from '../../pic/continent.json'
import asiaMap from '../../pic/asia.json'
import SN_flag from '../../pic/singapore.png'
import ID_flag from '../../pic/indonesia.png'
import MY_flag from '../../pic/malaysia.png'
import VM_flag from '../../pic/vietnam.png'
import { feature } from "topojson-client"

import 'chartjs-plugin-datalabels';

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
    display: function(context) {
        return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
    },
   color: 'white',
});



const styles = theme => ({
    card: {
        //maxWidth: 345,
        height: "15vh",
    },
    chart: {
        height: "40vh",
    },
    transaction: {
        backgroudColor: "rgba(188,32,75,1)",
        color: "snow"
    },
    cardAction: {
        width: "100%",
        height: "100%"
    },
    media: {
        // ⚠️ object-fit is not supported by IE11.
        objectFit: 'cover',
    },
    formControl: {
        marginLeft: theme.spacing.unit * 8,
        marginRight: theme.spacing.unit * 3,
    },
    checkbox: {
        color: blue[600],
        padding: 6,
        '&$checked': {
          color: blue[500],
        },
    },
    checked: {},
});
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const getState = () => ({
    datasets: [{
        data: [getRandomInt(50, 200), getRandomInt(100, 150), getRandomInt(150, 250)],
        backgroundColor: [
            '#CCC',
            '#36A2EB',
            '#FFCE56'
        ],
        hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56'
        ]
    }]
});

const map_data = {
    SN:{
        point: [103.8198, 1.3521],
        score: 16,
        name: "Singapore",
        flag: SN_flag,
    },
    ID:{
        point: [113.9213, 0.7893],
        score: 8,
        name: "Indonesia",
        flag: ID_flag,
    },
    MY:{
        point: [101.9758, 4.2105],
        score: 7,
        name: "Malaysia",
        flag: MY_flag,
    },
    VM:{
        point: [108.2772, 14.0583],
        score: 5,
        name: "Vietnam",
        flag: VM_flag,
    },
};

class Dashboard extends Component {
    constructor(props) {
		super(props);
        this.state = {
            selected_country:["SN","ID","MY","VM"],
            selected_compl:["Corporate Law","Foreign Exchange","Labour & Social Security","Tax", "Data Privacy and Protection Laws"],
        }
    };

    handleChange = (event, value) => {
         this.setState({ value });
    };
    handleSelectCountry = country => event =>{
        let prev_seleted_country = this.state.selected_country;
        let new_selected_country = prev_seleted_country.slice(0);
        // console.log("clicked")
        // console.log(prev_seleted_country.indexOf(country))
        if (prev_seleted_country.indexOf(country)>-1)
        {
            new_selected_country = new_selected_country.filter(e=> e!=country)
        } else {
            new_selected_country.push(country);
        }
        this.setState({
            selected_country: new_selected_country
        });
    };

    handleSelectCompl = compl => event =>{
        let prev_seleted_compl = this.state.selected_compl;
        let new_selected_compl = prev_seleted_compl.slice(0);
        // console.log("clicked")
        // console.log(prev_seleted_compl.indexOf(compl))
        if (prev_seleted_compl.indexOf(compl)>-1)
        {
            new_selected_compl = new_selected_compl.filter(e=> e!=compl)
        } else {
            new_selected_compl.push(compl);
        }
        this.setState({
            selected_compl: new_selected_compl
        });
    };

    getTasks(){
        axios.get(config.task)
        .then(res => {
            let tasks = res.data;
            let task_data = []
            task_data.push(tasks.filter(task=>task.status=='Draft').length)
            task_data.push(tasks.filter(task=>task.status=='Pending Response').length)
            task_data.push(tasks.filter(task=>task.status=='Pending Review').length)
            task_data.push(tasks.filter(task=>task.status=='Rejected').length)
            task_data.push(tasks.filter(task=>task.status=='Completed').length)
            // console.log("in dashboard")
            // console.log(res)
            // console.log(alert_data)
            let data={
                labels: [
                'Draft',
                'Pending Response',
                'Pending Review',
                'Rejected',
                'Completed'
                ],
                datasets:[{
                    data:task_data,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#990099',
                        '#ff9900'
                        ],
                        hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#FF6384',
                        '#36A2EB'
                        ]
                }]
            }
            this.setState({
                taskStatusData: data
            })
        })
    }
    getAlerts(){
        axios.get(config.allAlerts)
        .then(res => {
            let alerts = res.data;
            let alert_data = []
            alert_data.push(alerts.filter(alert=>alert.status=='Open').length)
            alert_data.push(alerts.filter(alert=>alert.status=='Investigating').length)
            alert_data.push(alerts.filter(alert=>alert.status=='Pending Review').length)
            alert_data.push(alerts.filter(alert=>alert.status=='Pending Approval For Closure').length)
            alert_data.push(alerts.filter(alert=>alert.status=='Closed').length)
            // console.log("in dashboard")
            // console.log(res)
            // console.log(alert_data)
            let data={
                labels: [
                'Open',
                'Investigating',
                'Pending Review',
                'Pending Approval For Closure',
                'Closed'
                ],
                datasets:[{
                    data:alert_data,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#990099',
                        '#ff9900'
                        ],
                        hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#FF6384',
                        '#36A2EB'
                        ]
                }]
            }
            this.setState({
                alertStatusData: data
            })
        })
    }
    getInitialState() {
        return getState();
    }

    componentDidMount(){
        this.getAlerts();
        this.getTasks();
    }

    componentWillMount() {
        this.setState(getState());
        // regular refresh
        // setInterval(() => {
        //     // this.setState(getState());
        //     this.getAlerts();
        //     this.getTasks();
        // }, 60000);
    }
    scaleBasedOnSelectedRisk = (origin,num_selected_risks) =>
    {
        return Math.floor(origin * num_selected_risks / 4)
    }
    handleClickMarker = (marker) => {
        //console.log(marker);
        const coordinates = marker.coordinates;
        for (var property in map_data) {
            if (map_data.hasOwnProperty(property)) {
                // console.log(coordinates[0]);
                if (coordinates[0]==map_data[property].point[0] && coordinates[1]==map_data[property].point[1])
                {
                    const country = map_data[property].name.toLowerCase();
                    //console.log(map_data[property].name);
                    this.props.history.push('/country dashboard?country='+country);
                }
            }
        }
    }
    render() {
        const { classes } = this.props;
        const {selected_country, selected_compl} = this.state;

        const base_radius_factor = 1.5;

        const comp_data = ["Corporate Law","Foreign Exchange","Labour & Social Security","Tax", "Data Privacy and Protection Laws"];

        const countries = feature(countriesMap, countriesMap.objects[Object.keys(countriesMap.objects)[0]]).features;
        // console.log(countries)
        // const countryList = ["Singapore","Indonesia","Malaysia","Vietnam","South Korea"];
        const countryFIPS = ["SN","ID","MY","VM"]
        const filteredCountries = countries.filter(country=>countryFIPS.includes(country.properties["FIPS"]))
        // console.log(countries.filter(country=>countryFIPS.includes(country.properties["FIPS"])));
        
        const continents = feature(continentMap, continentMap.objects[Object.keys(continentMap.objects)[0]]).features;
        // console.log(continents.concat(filteredCountries))

        const point_sg = [103.8198, 1.3521]
        // console.log(countryFIPS)
        // console.log(map_data)

        const countrySelection = (
            <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Region</FormLabel>
            <FormGroup>
                {countryFIPS.map(country=>(
                    <FormControlLabel 
                    control={
                        <Checkbox
                        checked={selected_country.indexOf(country)>-1}
                        onChange={this.handleSelectCountry(country)}
                        value={country}
                        classes={{
                            root: classes.checkbox,
                            checked: classes.checked,
                          }}
                        />
                    }
                    label={map_data[country].name}
                    />
                ))}
            </FormGroup>
          </FormControl>
        );

        const riskSelection = (
            <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Compliance</FormLabel>
            <FormGroup>
                {comp_data.map(compl=>(
                    <FormControlLabel 
                    control={
                        <Checkbox
                        checked={selected_compl.indexOf(compl)>-1}
                        onChange={this.handleSelectCompl(compl)}
                        value={compl}
                        classes={{
                            root: classes.checkbox,
                            checked: classes.checked,
                          }}
                        />
                    }
                    label={compl}
                    />
                ))}
            </FormGroup>
          </FormControl>
        );

        const markers_green = (
            selected_country.map(country=>{
                return (           
                    <Marker marker={{coordinates: map_data[country].point}}
                        onClick={ this.handleClickMarker }
                        style={{
                        default: { fill: setting.COLOR.GREEN, fillOpacity: 0.5, stroke:setting.COLOR.GREEN,strokeWidth:"1",strokeMiterlimit:10,},
                        hover: { fill: setting.COLOR.GREEN, fillOpacity: 0.5, stroke:setting.COLOR.GREEN,strokeWidth:"1",strokeMiterlimit:10,},
                        // pressed: { fill: "#0091DA", fillOpacity: 0.75, stroke:"#005EB8",strokeWidth:"1",strokeMiterlimit:10,},
                    }}
                    >
                        <circle cx={ 0 } cy={ 0 } r={ map_data[country].score * base_radius_factor * selected_compl.length} />
                    </Marker>
            )})
        )

        const markers_red = (
            selected_country.map(country=>{
                return (              
                    <Marker marker={{coordinates: map_data[country].point}}
                        onClick={ this.handleClickMarker }
                        style={{
                        default: { fill: setting.COLOR.RED, fillOpacity: 0.75, stroke:setting.COLOR.RED,strokeWidth:"1",strokeMiterlimit:10,},
                        hover: { fill: setting.COLOR.RED, fillOpacity: 0.75, stroke:setting.COLOR.RED,strokeWidth:"1",strokeMiterlimit:10,},
                        // pressed: { fill: "#0091DA", fillOpacity: 0.75, stroke:"#005EB8",strokeWidth:"1",strokeMiterlimit:10,},
                        }}
                    >
                        <circle cx={ 0 } cy={ 0 } r={ map_data[country].score * base_radius_factor * selected_compl.length / 2} />
                    </Marker>
            )})
        )

        const markers_numbers = (
            selected_country.map(country=>(
                <Marker marker={{coordinates: map_data[country].point}}
                        onClick={ this.handleClickMarker }
                        style={{
                        default: { fill: "#F44336", fillOpacity: 0.75, stroke:"#F44336",strokeWidth:"1",strokeMiterlimit:10,},
                        hover: { fill: "#F44336", fillOpacity: 0.75, stroke:"#F44336",strokeWidth:"1",strokeMiterlimit:10,},
                        // pressed: { fill: "#0091DA", fillOpacity: 0.75, stroke:"#005EB8",strokeWidth:"1",strokeMiterlimit:10,},
                        }}
                    >
                    <text x='10' font-size="20px" text-anchor="middle" fill="#FFF" stroke="#FFF" stroke-width="1" dy=".3em" fill-opacity="1" opacity="1">{Math.floor(map_data[country].score * selected_compl.length / 8)}</text>
                </Marker>
            ))
        )

        const markers_flags = (
            selected_country.map(country=>(
                <Marker marker={{coordinates: map_data[country].point}}
                    onClick={ this.handleClickMarker }
                        style={{
                        default: { fill: "#F44336", fillOpacity: 0.75, stroke:"#F44336",strokeWidth:"1",strokeMiterlimit:10,},
                        hover: { fill: "#F44336", fillOpacity: 0.75, stroke:"#F44336",strokeWidth:"1",strokeMiterlimit:10,},
                        // pressed: { fill: "#0091DA", fillOpacity: 0.75, stroke:"#005EB8",strokeWidth:"1",strokeMiterlimit:10,},
                        }}
                    >
                    <image x='-30' y='-17' xlinkHref={map_data[country].flag} height="30" width="30"/>
                </Marker>
            ))
        )

        const worldMap = (
            <div>
        <ComposableMap style={{ width: "100%", maxWidth: "1080px", height: "auto"}}>
          <ZoomableGroup zoom="5" center={point_sg}>
            <Geographies geography={ asiaMap }>
            {(geographies, projection) => geographies.map(geography => (
              <Geography
                key={ geography.id }
                geography={ geography }
                projection={ projection }
                style={{
                    default: {
                      fill: "#FFF",
                      stroke: "#aaaaaa",
                      strokeWidth: 0.2,
                      outline: "none",
                    },
                    // hover: {
                    //   pointerEvents: "none",
                    //   fill: "#FFF",
                    //   stroke: "#7b7c7c",
                    //   strokeWidth: 0.25,
                    //   outline: "none",
                    // }
                  }}
                />
            ))}
          </Geographies>
          <Geographies geography={ filteredCountries }>
            {(geographies, projection) => geographies.map(geography => (
              <Geography
                key={ geography.id }
                geography={ geography }
                projection={ projection }
                style={{
                    default: {
                      fill: "#FFF",
                      stroke: "#7a7a7a",
                      strokeWidth: 0.4,
                      outline: "none",
                    },
                    // hover: {
                    //   pointerEvents: "none",
                    //   fill: "#FFF",
                    //   stroke: "#7b7c7c",
                    //   strokeWidth: 0.25,
                    //   outline: "none",
                    // }
                  }}
                />
            ))}
          </Geographies>
          
            <Markers>
                {markers_green}
            </Markers>
            <Markers>
                {markers_red}
            </Markers>
            <Markers>
                {markers_numbers}
            </Markers>
            <Markers>
                {markers_flags}
            </Markers>
          </ZoomableGroup>
        </ComposableMap>                
            </div>
        );
        let sample_data_for_bar_chart = {
            'SN':{
                'name':'Singapore',
                'compliance': 16,
                'non_compliance': 8
            },
            'ID':{
                'name':'Indonesia',
                'compliance': 8,
                'non_compliance': 4
            },
            'MY':{
                'name':'Malaysia',
                'compliance': 7,
                'non_compliance': 3
            },
            'VM':{
                'name':'Vietnam',
                'compliance': 5,
                'non_compliance': 2
            }
        }

        let country_label = selected_country.map((element,index)=>{
            let sum = this.scaleBasedOnSelectedRisk(sample_data_for_bar_chart[element]['compliance'],selected_compl.length)+this.scaleBasedOnSelectedRisk(sample_data_for_bar_chart[element]['non_compliance'],selected_compl.length)
            return sample_data_for_bar_chart[element]['name'] + '  ('+sum+')';
        })

        let compliance_data = selected_country.map((element,index)=>{
            return this.scaleBasedOnSelectedRisk(sample_data_for_bar_chart[element]['compliance'],selected_compl.length);
        })

        let non_compliance_data = selected_country.map((element,index)=>{
            return this.scaleBasedOnSelectedRisk(sample_data_for_bar_chart[element]['non_compliance'],selected_compl.length);
        })

        console.log("compliance_data")
        console.log(compliance_data)

        const data = {
            labels: country_label,
            datasets: [
              {
                label: 'compliance',
                backgroundColor: setting.COLOR.GREEN,
                borderColor: setting.COLOR.GREEN,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: compliance_data
              },
              {
                label: 'non-compliance',
                backgroundColor: setting.COLOR.RED,
                borderColor: setting.COLOR.RED,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: non_compliance_data
              }
            ],
          };

        const compliance_by_region = (
            <HorizontalBar data={data}
                height={"25%"}
                options={{
                    legend: {
                        display:false,
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                            stacked: true,
                            // ticks:{
                            //     beginAtZero: true,
                            // },
                            // gridLines: {
                            //     display:false,
                            //     drawBorder: false,
                                
                            // }   
                        }],
                        yAxes: [{
                            stacked: true,
                            gridLines: {
                                display:false
                            }
                        }]
                    },
            }}/>
        );


        return (
            <div>
                <Grid container alignItems="center" justify='center' className={classes.dashboard}>                   
                    <Grid item lg={4} xl={2} style={{textAlign:"left"}}>
                    <Grid container alignItems="center" justify='center' spacing={16}>
                        <Grid item md={12}>{countrySelection}</Grid>
                        <Grid item md={12}>{riskSelection}</Grid>
                    </Grid>
                    </Grid>
                    <Grid item lg={8} xl={10}>
                        {worldMap}
                    </Grid>
                    <Grid item md={12}>
                        {selected_country.length>0 && <Typography variant="subtitle1">Compliance by Region</Typography>}
                        {compliance_by_region}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(Dashboard));