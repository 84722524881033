import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AlertChip from '../../AlertChip';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

let id = 0;
function createData(date, from, message) {
    id += 1;
    return { date, from, message };
}



class DescriptionTable2 extends Component {


    render() {
        const classes = this.props;
        //console.log(this.state);
        // console.log(classes);
        // console.log(classes.detail)
        return (
            <div className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                        {classes.detail.table_def.map(row => {
                        return (
                            <TableCell>
                                {row}
                            </TableCell>
                        );
                    }, this)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classes.detail.table_def.map(f => {
                        //console.log(classes.detail[f])
                        if(f =="flags"){
                            return(<TableCell numeric style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>
                                {classes.detail[f]["High Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'high'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}
                                {classes.detail[f]["Medium Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'mid'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}
                                {classes.detail[f]["Low Risk"].map(e => {
                                return(<AlertChip
                                        key={e}
                                        label={e}
                                        color={'low'}
                                        //avatar={n.flags.hightr}
                                        //style={{ margin: 2 }}
                                        />)
                                })}

                                </TableCell>)
                        }else{
                            return (<TableCell numeric>{classes.detail[f]}</TableCell>);
                        }
                    }, this)}
                        {/* <TableCell>55 Newton Road, #12-01 307987</TableCell>
                        <TableCell>88990065</TableCell>
                        <TableCell>sla@sla.com</TableCell>
                        <TableCell>Yongyi</TableCell>
                        <TableCell>
                            <AlertChip label={"Same Director"} color={"high"}/>
                            <AlertChip label={"Duplicate Address"} color={"high"}/>
                        </TableCell> */}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

DescriptionTable2.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DescriptionTable2);