import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AlertChip from '../AlertChip';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import config from "../../config/config";
import axios from 'axios';

let counter = 0;
function createData(PO_ID, flags, CreatedBy, Created_Date, Vendor_Name, amount) {
    counter += 1;
    return { id: counter, PO_ID, flags, CreatedBy, Created_Date, Vendor_Name, amount };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class EnhancedTableHead extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        //this.getTableHeader = this.getTableHeader.bind(this);
        this.state = {
            rows: [],
        };
    }

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    componentWillMount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        //this.getTableHeader();
        //console.log("header data load finish");
    }
    getTableHeader = () => {
        //console.log("test axios");

        axios.get(config.transaction)
            .then(res => {
                // console.log(res);
                if (this._isMounted) {
                    let headers = res.data.table_def;
                    // console.log(headers);
                    this.setState({ rows: headers });
                }
            });
    }


    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, fields } = this.props;
        // console.log("in header render")
        // console.log(this.props)
        // console.log(this.props.fields)

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" style={{ position: "sticky", left: 0, backgroundColor: "snow" }}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {/* <TableCell numeric style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>Flags</TableCell>
                    <TableCell numeric style={{ position: "sticky", left: "12vw", backgroundColor: "snow" }}>
                        Purchase Order ID
                    </TableCell> */}
                    {fields.map(row => {
                        return (
                            <TableCell>
                                {row}
                            </TableCell>
                            // <TableCell
                            //     key={row.id}
                            //     numeric={row.numeric}
                            //     padding={row.disablePadding ? 'none' : 'default'}
                            //     sortDirection={orderBy === row.id ? order : false}
                            // >
                            //     <Tooltip
                            //         title="Sort"
                            //         placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            //         enterDelay={300}
                            //     >
                            //         <TableSortLabel
                            //             active={orderBy === row.id}
                            //             direction={order}
                            //             onClick={this.createSortHandler(row.id)}
                            //         >
                            //             {row.label}
                            //         </TableSortLabel>
                            //     </Tooltip>
                            // </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;
    const searchBar = (<div style={{ width: "60vw" }}>
        <Grid container spacing={8} alignItems="flex-end">
            <Grid item>
                <SearchIcon />
            </Grid>
            <Grid item xs={10}>
                <TextField id="input-with-icon-grid" label="Search..." fullWidth />
            </Grid>
        </Grid>
        <Divider />
    </div>);
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subheading">
                        {numSelected} selected
          </Typography>
                ) : (
                        searchBar
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            {/* <DeleteIcon /> */}
                            to be implemented
                        </IconButton>                       
                    </Tooltip>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        //minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class TransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            data: [
            ],
            fields: [],
            page: 0,
            rowsPerPage: 10,
            showResultsWarning: true,
            length: 0,
            serverPage: 1
        };
        this.getTransactions = this.getTransactions.bind(this);
        //console.log(this.props.token);  
    }


    componentWillMount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type)
        {
            this.getTransactions(this.state.serverPage);
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.getTransactions(this.state.serverPage);
        // console.log("transaction data load finish");
    }
    getTransactions = (serverPage) => {
        // console.log("test axios");
        //console.log(config.transactionPagination(serverPage));
        axios.get(config.transactionPagination(serverPage), {params: { 'type': this.props.type }})
            .then(res => {
                let transactions = res.data.results.items;
                let fields = res.data.results.table_def;
                let dataLength = res.data.count;
                // console.log(this.props.type)
                // console.log(dataLength)
                // console.log(transactions)
                // console.log("in transactions")
                this.setState({ serverPage: serverPage, data: transactions, fields: fields, length: dataLength, showResultsWarning: true});
            });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.state.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        event.preventDefault();
        let preserverpage = this.serverPage;
        this.setState({ page: page/*, data: preserverpage == (Math.trunc(page / this.state.rowsPerPage) + 1) ? this.state.data : []*/}, ()=>{
            if(preserverpage != Math.trunc(page / this.state.rowsPerPage) + 1){
                this.getTransactions(Math.trunc(page / this.state.rowsPerPage) + 1);
            }
            //console.log("in handle change page");
            //console.log(this.state.page);
            //console.log("start index : "+(page * this.state.rowsPerPage - (this.state.serverPage-1) * 10 * this.state.rowsPerPage)+"to end index : "+(page * this.state.rowsPerPage + this.state.rowsPerPage - (this.state.serverPage-1) * 10 * this.state.rowsPerPage))
                                            
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    getFlagName(id){
    	switch (id){
    		case 1:
    			return "Missing Document";
    		case 2:
    		case 3:
    			return "Duplicate Entry";
    		case 4:
    		case 5:
    			return "Missing Amount";
    		default:
    			return "Amount Greater Than 30k";
    	}

    }

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, fields,length} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage - this.state.serverPage * 10 * rowsPerPage));
        // console.log('transaction table')
        // console.log(fields)

        return (
            <div className={classes.root}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <div className={classes.tableWrapper}>
                    <Grid container>
                        <Grid item md={12}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    token={this.props.token}
                                    fields={fields}
                                />
                                <TableBody>
                                    {data
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage - (this.state.serverPage-1) * 10 * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage - (this.state.serverPage-1) * 10 * rowsPerPage)
                                        .map(n => {
                                            //console.log(n.data)
                                            const isSelected = this.isSelected(n.id);
                                            let i = 0;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={event => this.handleClick(event, n.id)}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    selected={isSelected}
                                                >

                                                    <TableCell padding="checkbox" style={{ position: "sticky", left: 0, backgroundColor: "snow" }}>
                                                        <Checkbox checked={isSelected} />
                                                    </TableCell>
                                                    {
                                                        this.state.fields.map(f => {
                                                            if(f =="flags"){
                                                                return(<TableCell style={{ position: "sticky", left: "3vw", backgroundColor: "snow" }}>
                                                                    {n.flags["High Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e/*this.getFlagName(e)*/}
                                                                            color={'high'}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}
                                                                    {n.flags["Medium Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e/*this.getFlagName(e)*/}
                                                                            color={'mid'}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}
                                                                    {n.flags["Low Risk"].map(e => {
                                                                    i++;
                                                                    return(<AlertChip
                                                                            key={i}
                                                                            label={e/*this.getFlagName(e)*/}
                                                                            color={'low'}
                                                                            taskRefresh = {this.props.taskRefresh}
                                                                            //avatar={n.flags.hightr}
                                                                            //style={{ margin: 2 }}
                                                                            />)
                                                                    })}

                                                                    </TableCell>)
                                                            }else{
                                                                return (<TableCell>{n[f]}</TableCell>);
                                                            }
                                                        })
                                                    }
                                                    

                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        {/* <Grid item md={6}>
                            <div style={{ width: "100%", height: "45vh" }}>
                                <Toolbar>Transaction Details</Toolbar>
                                <div style={{ height: "38vh", overflow: "auto" }} >
                                    <TransactionDetailTable />
                                </div>
                            </div>
                        </Grid> */}

                    </Grid>
                </div>
                <TablePagination
                    component="div"
                    count={length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // rowsPerPageOptions={10}
                    // labelDisplayedRows={()=>`${page*rowsPerPage}-${(page+1)*rowsPerPage} of ${length}`}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

TransactionTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransactionTable);