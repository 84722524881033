import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormControl';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AttachFile from '@material-ui/icons/AttachFile';

import config from "../../config/config";
import axios from 'axios';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

class TaskResponseDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            scroll: 'paper',
            tasks: this.props.tasks
        };
    }
    handleClose = () => {
        this.setState({ open: false });
        this.props.close();
    };
    handleResponseOnChange = (e, id) =>{
        // console.log(e.target.value + " in " +id)
        let updateObj = this.state.tasks.questions.find(o=>o.id == id);
        let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);
        if(e.target.name === "response"){
            // console.log(updatequestionIndex)
            updateObj.response = e.target.value;
            this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
            this.forceUpdate();
        } else if (e.target.name === "reason")
        {
            // console.log(updatequestionIndex)
            updateObj.reason = e.target.value;
            this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
            this.forceUpdate();
        }
    };

    ResponseFileUpdateRequest = (formData) => {
        return axios.post(config.attachment, formData, {
             headers: {
                 'Content-Type': 'multipart/form-data'
             }
         }).then((res)=>{
             console.log("upload file res");
             console.log(res);
            //  this.setState({ tempID: res.data.id });
             return res.data.id;
         })
             .catch(err=>{ console.log(err.response); });
    };

    handleResponseAttachmentOnChange = (e, id) =>{
        let updateObj = this.state.tasks.questions.find(o=>o.id == id);
        let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);

        let attachment = e.target.files[0];

        var formData = new FormData();
        var uploads = attachment;
        // console.log(uploads);
        formData.append("file", uploads);
        // console.log ("formdata");
        // console.log(formData);

        this.ResponseFileUpdateRequest(formData).then(data => {
            // console.log(data)
            updateObj.attachment_id = data;
            updateObj.attachments.push(uploads);
            console.log("updateobj");
            console.log(updateObj);
            this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});
            this.forceUpdate();
        });        
    };


    ResponsePutRequest = (postObj) => {
        return axios.put(config.tasksSub(this.state.tasks.id),postObj)
            .then(res=>{
                console.log("this is the reesult of post request");
                console.log(res)})
            .catch(error=>{console.log(error.response)});
        
    };




    handleResponseSubmit = () => {
        let postObj = this.createResponseSubmitObj();
        this.ResponsePutRequest(postObj).then(res => {
            this.props.refreshTaskList();
            this.setState({ open: false });
            this.props.close();
        });
    };
    createResponseSubmitObj(){
        let submitObj = {};
        submitObj.id = this.state.tasks.id;
        submitObj.questions = this.state.tasks.questions;
        console.log("submit response obj");
        console.log(submitObj);
        return submitObj;
    };
    handleRadioGroupChange = (e, id) => {
        // console.log("in radio change")
        // console.log(id);
        let updateObj = this.state.tasks.questions.find(o=>o.id === id);
        // console.log(updateObj)
        let updatequestionIndex = this.state.tasks.questions.indexOf(updateObj);
        updateObj.response = e.target.value;
        this.setState(state => {this.state.tasks.questions[updatequestionIndex] = updateObj});

        this.forceUpdate();
    };
    renderQuestion(t){
        // console.log("in question")
        // console.log(this.state.tasks.questions[t.id-1].response)
        // console.log(t)
        switch (t.question_type.toLowerCase())
        {
            case "attachment":
            return (
            <span>
                <input
                    hidden
                    id={"uploadFileButtonForResponse" + t.id }
                    accept=".xls,.xlsx,.pdf,.doc,.docx,image/*"
                    // multiple 
                    type="file"
                    name={"attachemnt_id"}
                    onChange = {(e) => {this.handleResponseAttachmentOnChange(e, t.id)}}
                />
                <label htmlFor={"uploadFileButtonForResponse" + t.id}>
                    <Button variant="contained" component="span" size="small" >
                    <AttachFile />
                    Upload
                    </Button>
                    {t.attachments.length>0 ? <span style={{marginLeft:"1vw"}}>{t.attachments[0].name}</span>: null}
                </label>
            </span>
            );
            case "numbers":
            return (
            <TextField
                margin="dense"
                id="name"
                placeholder="your response here"
                fullWidth
                name = {"response"}
                type = "number"
                onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
            />
            );
            case "boolean":
            return (
                <div style={{marginLeft: "2vw"}}>
                    <FormGroup>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Gender"
                                name="gender1"
                                value={t.response}
                                onChange={(e) => {this.handleRadioGroupChange(e, t.id)}}
                                row
                                required="required"
                                style={{ display: 'inline-flex' }}
                            >
                                <FormControlLabel value="True" control={<Radio />} label="Yes" />
                                <FormControlLabel value="False" control={<Radio />} label="No" />
                                <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>    
                    {t.response == "N/A" ? 
                    <div style={{width: '90%'}}><FormGroup style={{width: '100%'}}>
                        <FormControl style={{width: '100%'}}>
                            <TextField
                                margin="dense"
                                id="name"
                                placeholder="reasons for selecting N/A"
                                fullWidth
                                multiline                           
                                type="text"
                                name = {"reason"}
                                onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
                            />
                        </FormControl>
                    </FormGroup></div> : null}
                </div>
                );
            case "date":
            return (
                <TextField
                    margin="dense"
                    id="name"
                    placeholder="your response here"
                    fullWidth
                    type="date"
                    name = {"response"}
                    onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
                />
                );
            case "text":
            return (
                <TextField
                    margin="dense"
                    id="name"
                    placeholder="your response here"
                    fullWidth
                    name = {"response"}
                    onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
                />
                );
            default:
            return (
                <TextField
                    margin="dense"
                    id="name"
                    placeholder="your response here"
                    fullWidth
                    name = {"response"}
                    onChange = {(e) => {this.handleResponseOnChange(e, t.id)}}
                />
                );
        }
    }

/*    componentDidMount(){
            this.setState({tasks:this.props.tasks});
    }*/
    render() {
        // console.log(this.state.tasks)
        let question_index = 0;
        let responseView = (<div>
            {/* <div style={{ height: "1vh" }}></div>
            <Typography variant="subheading">{this.props.tasks.description}</Typography> */}
            <div style={{ height: "1vh" }}></div>
            {this.state.tasks.questions
                .sort((a,b)=>(a.id > b.id) ? 1 : ((b.id > a.id)? -1 : 0))
                .map(t => {
                    // console.log(t);
                    question_index ++;
                    return (
                        <div key = {t.id}>
                            <Typography variant="subheading">{question_index+'. '+t.question_text + "*"}</Typography>
                            {this.renderQuestion(t)}                          
                            <div style={{ height: "2vh" }}></div>
                        </div>
                    )
                })
            }
        </div>);
        return (
            <Dialog
                open={this.state.open}
                //onClose={this.handleClose}
                scroll={this.state.scroll}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">{this.props.tasks.description}</DialogTitle>
                <DialogContent style={{ width: "40vw", height: "50vh", maxWidth: "500px"}}>
                    {responseView}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                            Discard
                    </Button>
                    <Button onClick={this.handleResponseSubmit}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

TaskResponseDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskResponseDialog)