// const url = "http://localhost:8000/api";
// const url = "http://da-test:3031/api";
// const url = "//regcompliance.forensic-da.com/api";
const url = process.env.REACT_APP_URL;
const endpoints = {
    closeAlert: url + "/alerts/close/",
    openAlert: url + "/alerts/list_open/",
    allAlerts: url + "/alerts/list_all/",
    alertsByCountry: url + "/alerts/list_by_country",
    alerts: url + "/alerts",
    alert: url + "/alerts/",
    tasksTab: url + "/tasks/",
    taskTemplate: (id) => {
        return url + "/tasktemplate/?compliance_id=" + id;
    },
    // tasks: (alertId) =>{
    //     return url + "/alerts/"+ alertId + "/tasks/";
    // },
    tasks: (alertId) =>{
        return url + "/tasks/?compliance_id="+ alertId;
    },
    taskRespondent: (taskId) =>{
        return url + "/tasks/" + taskId;
    },
    task: url + "/tasks/",
    tasksSub: (taskktId) => {
        return url +  "/tasks/" + taskktId + "/" + "respond/";
    },
    log: (alertId) => {
        return "/auditlogs/?id="+ alertId;
    },
    transaction: url + "/transactions",
    transactionPagination: (serverPage) => {
        return url + "/transactions" + "?page="+serverPage
    },
    entities: (type) =>{
        return url + "/entities?type=" + type;
    },
    entity:(entityID) => {
        return url + "/entities/" + entityID;
    },
    entity_transaction: (entityId) => {
        return "/entities/"+entityId+"/transactions";
    },
    auth: url + "/auth",
    menu: url + "/menus",
    attachment: url + "/upload",
    assigneeList: url + "/users/list_of_assignee/"

};

export default endpoints;
