import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    body1: {
        paddingLeft: "24px",
    },
});


class RegCompRoutineDetailTable extends Component {
    constructor(props) {
        super(props);
        // console.log("received props for list detail");
        // console.log(props);
        this.state = {
            value: "open",
        }
    }

    render() {
        const {classes, selectedAlert} = this.props;
        return (
            <div className={classes.root}>
                <Toolbar>Trigger Event</Toolbar>                
                <Typography variant="body" className={classes.body1} gutterBottom> {selectedAlert ? selectedAlert.trigger_event : null}
                    {/* "There are legal protections against discrimination with respect to employment based on race, gender, religion, sexual orientation, and political views. 
                    The company is prohibited from terminating the employment of a worker because of the worker is of different understanding or belief, religion, political orientation, ethnicity, colour, race, sex, physical condition or marital status. 
                    Indonesia has ratified ILO Convention Number 111 by Law Number 21 of 1999. */}
                </Typography>
                <Toolbar>Penalty</Toolbar>
                <Typography variant="body" className={classes.body1} gutterBottom> {selectedAlert ? selectedAlert.consequences : null}
                    {/* Termination due to discriminative reasons is declared null and void by law, and the company must reemploy the affected worker. */}
                </Typography>
                <Toolbar>Applicable Law</Toolbar>
                <Typography variant="body" className={classes.body1} gutterBottom> {selectedAlert ? selectedAlert.law : null}
                    {/* Article 158 of Labour Law */}
                </Typography>
            </div>
        )
    }
}

RegCompRoutineDetailTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegCompRoutineDetailTable);